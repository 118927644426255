import { Button, Col, Form, Input, Modal, Row, Select, Space } from "antd"
import { useEffect } from "react"

const { Option } = Select

const DineInModal = ({ visible, toggle, setFormData, orderInfo, buttonType, waiters, tables }) => {

    const totalGuest = orderInfo && orderInfo.total_guest ? orderInfo.total_guest : null;
    const table = orderInfo && orderInfo.table ? orderInfo.table : null;
    const waiter = orderInfo && orderInfo.waiter ? orderInfo.waiter : null;

    const [orderForm] = Form.useForm()

    useEffect(() => {
        if (buttonType === 1 || buttonType === 2 || buttonType === 3)
            orderForm.resetFields()

    }, [orderInfo])

    const onFinish = (values) => {
        const json = JSON.stringify(values)
        setFormData({ json, orderType: 0 })
        toggle(false)
    }

    return (
        <Modal
            width="60%"
            title="Dine In"
            centered
            open={visible}
            onCancel={() => toggle(false)}
            footer={[
                <Space key="space">
                    <Button type="primary" key="sbmit" onClick={orderForm.submit}> Submit </Button>
                    <Button key="back" onClick={() => toggle(false)}>
                        Close
                    </Button>
                </Space>
            ]}>
            <Form form={orderForm} onFinish={onFinish}>
                <Row gutter={16}>

                    <Col span={12}>
                        <Form.Item
                            name="total_guest"
                            label="Total Guests"
                            key={totalGuest}
                            labelCol={{ span: 24 }}
                            initialValue={totalGuest}
                            rules={[{ required: true, message: 'Field is required' }]}
                        >
                            <Input type="number" placeholder="Total Guests" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="table"
                            label="Table"
                            key={table}
                            labelCol={{ span: 24 }}
                            initialValue={table}
                            rules={[{ required: true, message: 'Field is required' }]}
                        >
                            <Select allowClear placeholder="Select Table" style={{ width: '100%' }}>
                                {tables.map(({ id, name }) => (<Option key={id} value={id}>{name}</Option>))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="waiter"
                            label="Waiter"
                            key={waiter}
                            labelCol={{ span: 24 }}
                            initialValue={waiter}
                            rules={[{ required: true, message: 'Field is required' }]}
                        >
                            <Select allowClear placeholder="Select Waiter" style={{ width: '100%' }}>
                                {waiters.map(({ id, name }) => (<Option key={id} value={id}>{name}</Option>))}
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>
        </Modal >
    )
}

export default DineInModal