import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, Space, Table, Typography } from "antd";
import windowSize from "react-window-size";
import { getCashRegisters } from "../../data";
import moment from "moment";
import { PAGINATION_OPTIONS } from "../../constants";

const { Title, Text } = Typography
function CashReport() {

    const [dataSource, setDataSource] = useState([])
    const [forceRefresh, setForceRefresh] = useState(true)
    let history = useHistory()

    let format = 'HH:mm:ss a DD/MMM/yyy';

    const columns = [
        {
            title: 'User Name',
            key: 'user_id',
            render: (text, record) => (<Text>{record.users.name}</Text>)
        },
        {
            title: 'Opening Amount',
            key: 'opening_amount',
            render: (text, record) => (<p style={{ textAlign: 'center' }}>{record.opening_amount}</p>)
        },
        {
            title: 'Closing Amount',
            key: 'closing_amount',
            render: (text, record) => (<p style={{ textAlign: 'center' }}>{record.closing_amount}</p>)
        },
        {
            title: 'Difference',
            key: 'difference',
            render: (text, record) => (<p style={{ textAlign: 'center' }}>{record.difference}</p>)
        },
        {
            title: 'Opening Time',
            key: 'opening_time',
            render: (text, record) => (<Text>{moment(record.opening_time).format(format)}</Text>)
        },
        {
            title: 'Closing Time',
            key: 'closing_time',
            render: (text, record) => (<Text>{moment(record.closing_time).format(format)}</Text>)
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <Space >
                    <Button onClick={() => history.push({ pathname: "/cash-reports/details/" + record.id })}>Details</Button>
                </Space>
            )
        },
    ]

    const initialize = async () => {
        let response = await getCashRegisters(forceRefresh)
        // console.log(response);
        if (response != undefined) {
            setDataSource(response);
            setForceRefresh(false)
        }
    }

    useEffect(() => {
        initialize()
    }, [])

    const renderHeading = () => {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Title level={3}>Cash Report</Title>
                </div>
            </>
        )
    }

    return (
        <>
            <Card>
                {renderHeading()}
                <br />
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={{ ...PAGINATION_OPTIONS }}
                />
            </Card>
        </>
    )

}

export default windowSize(CashReport)