import Dexie from 'dexie';

let dbInstance = null;

export function initializeDb() {
    if (!dbInstance) {
        const db = new Dexie('pos');

        db.version(1).stores({
            product: `++id,product_id,name,price,image,isCombo,category_id,cost_price,sku,addons`,
            productCombo: `++id,product_id,item_id,deal_price,quantity`,
            order: `++id,temporary_order_id,order_number,store_id,branch_id,status,date,name,phone,customer_id,subtotal,shipping,discount,total,notes,amount_changed,amount_paid,platform,cash_register_id,is_grocery_pos,address,order_id,order_type,order_table,time,total_guest,waiter`,
            orderItem: `++id,cost_price,isCombo,itemTotal,order_id,product_id,name,price,quantity,rowDiscountAmount,rowSubtotal,addons,options`
        });
    
        console.log('Database initialized');

        dbInstance = db;
    }

    return dbInstance;
}

export async function insertProducts(db, product, combo) {

    if (combo) {

        let p = {
            product_id: product.product_id,
            item_id: product.item_id,
            deal_price: product.deal_price,
            quantity: product.quantity
        }

        await db.productCombo.add(p);
        console.log("combo added");

    } else {

        let p = {
            id: product.id,
            product_id: product.product_id,
            name: product.name,
            price: product.price,
            image: product.image,
            isCombo: product.isCombo,
            category_id: product.category_id,
            cost_price: product.cost_price,
            sku: product.sku,
            addons: JSON.stringify(product.addons)
        }

        await db.product.add(p);
        console.log("product added");
    }
}

//Todo: not called anywhere
export async function getProduct(db, pid, combo) {

    let result;

    if (combo) {
        result = await db.productCombo.where("item_id").equals(pid).toArray();
    } else {
        result = await db.product.where("product_id").equals(pid).toArray();
    }

    return result;
}

export async function getProductByProductID(db, id, combo) {

    let result;

    if (combo) {
        result = await db.productCombo.where("product_id").equals(id).toArray();
    } else {
        result = await db.product.where("product_id").equals(id).toArray();
    }

    if (result.length > 0) {
        return [result];
    } else {
        return [];
    }
}

export async function getAllProducts(db) {

    let results = await db.product.toArray();

    if (results.length > 0) {
        return results;
    } else {
        return [];
    }
}

export async function getAllProductCombo(db) {

    let result = await db.productCombo.toArray();

    if (result.length > 0) {
        return result;
    } else {
        return []
    }
}

export async function updateProduct(db, product, rowid, combo) {

    if (combo) {
        await db.productCombo.update(rowid, { item_id: product.item_id, deal_price: product.deal_price, quantity: product.quantity });
        console.log("productcombo updated");
    } else {
        await db.product.update(rowid, { price: product.price });
        console.log("product updated");
    }
}

//Todo: not called anywhere
export async function removeProduct(db, id, combo) {

    if (combo) {
        await db.productCombo.delete(id);
        console.log("combo deleted");
    } else {
        await db.product.delete(id);
        console.log("product deleted");
    }
}

export async function truncateProducts(db) {
    await db.product.clear()
    console.log("all products deleted");
}

export async function truncateProductCombo(db) {
    await db.productCombo.clear()
    console.log("all product combos deleted");
}

export async function filterProducts(db, categoryId, keyword) {
    let results;

    if (categoryId || keyword) {

        results = await db.product.toArray();

        if (categoryId) {
            results = results.filter(r => r.category_id == categoryId);
        }

        if (keyword) {
            results = results.filter(r => r.name.match(keyword) || r.sku.match(keyword));
        }

    } else {
        results = await db.product.toArray();
    }

    return results;
}

export async function insertOrders(db, order) {

    let orderRow = {
        temporary_order_id: order.temporary_order_id,
        order_number: order.order_number,
        store_id: order.store_id,
        branch_id: order.branch_id,
        status: order.status,
        date: order.date,
        name: order.name,
        phone: order.phone,
        customer_id: order.customer_id,
        subtotal: order.subtotal,
        shipping: order.shipping,
        discount: order.discount,
        total: order.total,
        notes: order.notes,
        amount_changed: order.amount_changed,
        amount_paid: order.amount_paid,
        platform: order.platform,
        cash_register_id: order.cash_register_id,
        is_grocery_pos: order.is_grocery_pos,
        address: order.address,
        order_id: order.order_id,
        order_type: order.order_type,
        order_table: order.order_table,
        time: order.time,
        total_guest: order.total_guest,
        waiter: order.waiter
    }

    let lastInsertRowId = await db.order.add(orderRow);
    console.log("order added");
    return lastInsertRowId;
}

export async function getOrder(db, oid) {
    return await db.order.where("id").equals(oid).toArray();
}

export async function getAllOrder(db) {
    let results = await db.order.toArray();

    if (results.length > 0) {
        return results;
    } else {
        return [];
    }
}

export async function insertOrderItems(db, item) {

    let orderItem = {
        id: item.id,
        cost_price: item.cost_price,
        isCombo: item.isCombo,
        itemTotal: item.itemTotal,
        order_id: item.order_id,
        product_id: item.product_id,
        name: item.name,
        price: item.price,
        quantity: item.quantity,
        rowDiscountAmount: item.rowDiscountAmount,
        rowSubtotal: item.rowSubtotal
    }

    await db.orderItem.add(orderItem);
    console.log("combo added");
}

export async function getOrderItems(db, orderID) {

    let results = await db.orderItem.where("order_id").equals(orderID).toArray();
    if (results.length > 0) {
        return results; // Pass the array of order items
    } else {
        return null; // Pass null if no items found
    }
}

export async function truncateOrders(db) {
    await db.order.clear()
    console.log("all orders deleted");
}

export async function truncateOrderItems(db) {
    await db.orderItem.clear()
    console.log("all orders deleted");
}

export async function filterProductsByCategoryID(db,categoryId){

    if (!db) {
        db = dbInstance
    }

    let response =  await db.product.filter(p => p.category_id == categoryId).limit(20).toArray();

    return response;
}

export async function filterProductsByKeyword(db, keyword){

    if (!db) {
        db = dbInstance
    }

    let response = await db.product.where('name').anyOfIgnoreCase(keyword).or('name').startsWithIgnoreCase(keyword)
                                    .or('sku').anyOfIgnoreCase(keyword).or('sku').startsWithIgnoreCase(keyword)
                                    .limit(20)
                                    .toArray();
    return response;
}
export async function getProductsByProductID(db, ids) {
    if (!db) {
        db = dbInstance
    }

    return await db.product.filter(product => ids.includes(product.product_id)).toArray();
}