import { useEffect, useState } from "react";
import { Button, Modal, Table, Tag, Space } from "antd"

const KitchenModal = ({ visible, toggle, orders, products, resumed, customers }) => {

    const [data, setData] = useState([])

    const columns = [
        {
            title: 'Order Number',
            dataIndex: 'order_number',
            key: 'order_number',

        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        }, {
            title: 'Customer',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <>
                    <span>{record.name}</span><br />
                    <span>{record.email}</span><br />
                    <span>{record.phone}</span>
                </>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: text => (<Tag color={'geekblue'}>{text ? text.toUpperCase() : ''}</Tag>),
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <Space>
                    <Button onClick={() => handleLoad(record.id)}>Load</Button>
                    {/* <Button onClick={() => handleComplete(record.id)}>Complete</Button> */}
                </Space >
            ),
        },
    ];

    function sumProducts(products) {
        const summedProducts = products.reduce((acc, curr) => {
            const index = acc.findIndex(p => p.product_name === curr.product_name);
            if (index === -1) {
                acc.push(curr);
            } else {
                acc[index].quantity += curr.quantity;
            }
            return acc;
        }, []);
        return summedProducts;
    }

    const handleLoad = (id) => {
        let order = orders.find(i => i.id === id);

        let customer = customers.find(i => i.name === order.name);
        let customer_id = customer ? customer.id : null;

        let rows = [];

        const summedProducts = sumProducts(order.orderItems);

        summedProducts.map((item) => {
            const product_id = products.find(i => i.name === item.product_name).product_id
            let row = {
                id: product_id,
                name: item.product_name,
                price: item.selling_price,
                quantity: item.quantity,
                product_id: product_id,
            }
            rows.push(row)
        })

        let orderType = 3;
        let formData = {};

        if (order.time) {
            formData = { 'time': order.time };
            orderType = 2;
        } else if (order.address) {
            formData = { 'address': order.address };
            orderType = 1;
        } else if (order.total_guest || order.table_no || order.waiter) {
            formData = {
                'total_guest': order.total_guest,
                'table': order.table_no,
                'waiter': order.waiter
            };
            orderType = 0;
        }

        toggle(false)
        resumed(order.id, JSON.stringify(rows), customer_id, JSON.stringify(formData), parseInt(orderType), 'load')
    }

    useEffect(() => {
        if (orders) {
            setData(orders)
        }
    }, [orders, data])

    return (
        <Modal
            width={"60%"}
            title="Kitchen"
            centered
            open={visible}
            onCancel={() => toggle(false)}
            footer={
                <>
                    <Button key="back" onClick={() => toggle(false)}>Close</Button>
                </>
            }
        >
            <Table
                dataSource={data}
                columns={columns}
            // pagination={{ ...PAGINATION_OPTIONS }}
            />
        </Modal >
    )
}

export default KitchenModal
