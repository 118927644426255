import { Button, Form, Space, Col, Input, Modal, Row, TimePicker } from "antd"
import moment from "moment"
import { useEffect } from "react"


const TakeawayModal = ({ visible, toggle, setFormData, orderInfo, buttonType, currentTime, format }) => {

    const time = orderInfo && orderInfo.time ? moment(orderInfo.time, format) : moment(currentTime, format);

    const [orderForm] = Form.useForm()

    useEffect(() => {
        if (buttonType === 0 || buttonType === 1 || buttonType === 3)
            orderForm.resetFields()
    }, [time])

    const onFinish = (values) => {
        const json = JSON.stringify({ time: moment(values.time).format(format) })
        setFormData({ json, orderType: 2 })
        toggle(false)
    }

    return (
        <Modal
            width="60%"
            title="Take Away"
            centered
            open={visible}
            onCancel={() => toggle(false)}
            footer={[
                <Space key="space">
                    <Button type="primary" key="sbmit" onClick={orderForm.submit}> Submit </Button>
                    <Button key="back" onClick={() => toggle(false)}>
                        Close
                    </Button>
                </Space>
            ]}>
            <Form form={orderForm} onFinish={onFinish}>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="time"
                            label="Time"
                            key={time}
                            labelCol={{ span: 24 }}
                            initialValue={time}
                            rules={[{ required: true, message: 'Field is required' }]}
                        >
                            <TimePicker style={{ width: '100%' }} format={format} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal >
    )
}

export default TakeawayModal