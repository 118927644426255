const UrlService = {
    profile: 'profile',
    orders: 'orders',
    status: 'status',
    orderFilter: 'orders/filter',
    customers: 'customers',
    cashRegister: 'cash-register',
    productFilter: 'products/filter',
    saleScreen: 'sales-screen',
    placeOrder: 'sales-screen/placeOrder',
    cashRegisterOrder: 'sales-screen/cashRegisterOrder',
    user: 'user',
    stores: 'stores',
    storeSettings: 'store-settings',
    directions : 'directions',
    productFeatured:'featured/products',
}

export default UrlService;