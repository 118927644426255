import { Button, Col, Modal, Row, message } from "antd";
import { useCart } from "react-use-cart";
import { PlaceOrder } from "../../../data";
import OrderStatus from "../../../constant/orderStatus";
import moment from "moment";
import Limits from "../../../constant/storeLimits";
import { useEffect, useState } from "react";

const CartFooter = ({
    openModal,
    openSellModal,
    printOrder,
    shipping,
    notes,
    subtotal,
    formData,
    setFormData,
    setKitchenButtons,
    waiters,
    tables,
    setCustomerId,
    branch_id,
    customer,
    customerId,
    kitchenButtons,
    orderId,
    setOrderId,
    cartSummaryTotal,
    discount,
    setDiscount,
    setAmountDiscount,
    setPercentageDiscount,
    setShippingCharges,
    setNotes,
    store,
    enableCartParking
}) => {

    // For order limits
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const startDate = `${year}-${month}-01`;
    const todayDate = `${year}-${month}-${day}`;
    const [currency,setCurrency] = useState('')

    let orders = store?.orders ?? null;
    let storeRestriction = null;

    if (orders) {
        orders = orders.filter(i => moment(i.date).format('Y-M-DD') >= startDate && moment(i.date).format('Y-M-DD') <= todayDate);
        storeRestriction = store && store.store_restrictions ? store.store_restrictions : null;
    }


    const { items, emptyCart } = useCart();

    let total = cartSummaryTotal

    let totalBalance = 0

    totalBalance = total

    const orderInfo = formData?.json ? JSON.parse(formData.json) : formData
    const waiter = orderInfo?.waiter ? waiters.find(i => i.id === orderInfo.waiter || i.name === orderInfo.waiter).name : null
    const table = orderInfo?.table ? tables.find(i => i.id === orderInfo.table || i.name === orderInfo.table).name : null

    const sendToKitchen = async () => {

        if (Object.keys(formData).length === 0) {
            return message.error('Before proceeding with the sale, please select one of the available actions (Dine in, Delivery, or Takeaway).')
        }

        let data = {
            subtotal: subtotal,
            shipping: shipping,
            discount: discount,
            total: total,
            notes: notes,
            customer_id: customerId,
            name: customer ? customer.name : null,
            phone: customer ? customer.phone : null,
            cash_register_id: localStorage.getItem('cashRegister_id'),
            order_type: formData.orderType ?? kitchenButtons,
            total_guest: orderInfo.total_guest ?? null,
            table: table,
            waiter: waiter,
            address: orderInfo.address ?? null,
            time: orderInfo.time ?? null,
            branch_id: branch_id,
            is_grocery_pos: false,
            status: OrderStatus.IN_QUEUE,
            order_id: orderId,
            platform: 'POS',
            order_items: [],
        }

        let modifiedItems = items.map(item => {

            let addons = [];
            let options = [];

            if(item.addons && item.addons.length > 0){
                item.addons.map(addon => {
                    if(addon.children && addon.children.length > 0){
                        addon.children.map(addonItem => {
                            if(addonItem.selected){
                                let row = {
                                    title : addon.title,
                                    item : addonItem.item,
                                    price : addonItem.price,
                                    type : 1,
                                    selected:true
                                }
        
                                addons.push(row);
                            }
                            return addonItem;
                        })
                    }
                    return addon;
                })
            }

            if(item.options && item.options.length > 0){

                item.options.map(option => {
                    if(option.children && option.children.length > 0){
                        option.children.map(optionItem => {
                            if(optionItem.selected){
                                let row = {
                                    title : option.title,
                                    item : optionItem.item,
                                    price : optionItem.price,
                                    type : 0,
                                    selected:true                            
                                }
                                options.push(row)
                            }
                            return optionItem;
                        })
                    }
                    return option;
                })   
            }
            
            item.addons = addons;
            item.options = options;
            return item;                   
        })

        data.order_items = modifiedItems;

        const response = await PlaceOrder(data)
        if (response) {
            emptyCart();
            setKitchenButtons(3);
            setFormData({});
            totalBalance = 0;
            setCustomerId(0);
            setOrderId(0);
            setDiscount(0)
            setAmountDiscount(0)
            setPercentageDiscount(0)
            setShippingCharges(0)
            setNotes('')
        }
    }

    const onClick = () => {
        if (orders) {
            if (!storeRestriction || storeRestriction.find(i => i.type === Limits.POS_ORDER_LIMITS)?.value !== orders.length) {
                sendToKitchen()
            } else {
                Modal.error({
                    title: 'Exceeding  Limits',
                    content: Limits.MESSAGE,
                })
            }
        } else {
            sendToKitchen()
        }
    }

    useEffect(() => {
        setCurrency(localStorage.getItem('currency'))
    },[])

    return (
        <Row gutter={16} style={{ marginTop: 6 }}>
            <Col span={enableCartParking ? 5 : 6}>
                <Button style={{ width: '100%', background: '#666652', color: 'white' }}>
                    {currency} {(total).toFixed(2)}
                </Button>
            </Col>
            <Col span={enableCartParking ? 4 : 6}>
                <Button style={{ width: '100%', background: '#36ad56', color: 'white' }} onClick={() => openSellModal(true)}>Sell (s)</Button>
            </Col>
            {
                enableCartParking &&
                <Col span={4}>
                    <Button style={{ width: '100%', background: '#adab36', color: 'white' }} onClick={() => openModal('holdItems')}>Hold (h)</Button>
                </Col>
            }
            <Col span={6}>
                <Button style={{ width: '100%', background: '#1E90FF', color: 'white' }} onClick={onClick}>Send to kitchen</Button>
            </Col>
            <Col span={enableCartParking ? 5 : 6}>
                <Button style={{ width: '100%', background: '#003366', color: 'white' }} onClick={printOrder}>Print (p)</Button>
            </Col>
        </Row>
    )
}
export default CartFooter