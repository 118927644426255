import { useEffect, useState } from "react";
import { Button, Card, List, Typography, Image, AutoComplete, message, Spin, Empty } from "antd"
import { useCart } from "react-use-cart";
import { getProductByProductID } from "../../../services/sql-service";

const { Text } = Typography;

const Products = ({ products, search, loading, children, setProduct, setAddonModal }) => {

    const { addItem } = useCart();
    const [filteredData, setFilteredData] = useState([]);
    const [input, setInput] = useState('');
    const [currency, setCurrency] = useState('')

    const addToCart = async (item, quantity = 1) => {

        let items = JSON.parse(item.addons);

        let { addons } = items;

        if (addons && addons.length > 0) {
            setProduct(item)
            setAddonModal(true)
        } else {

            const d = new Date();
            let time = d.getTime();

            let row = {
                id: time,
                identifier: item.id,
                name: item.name,
                price: item.price,
                quantity,
                product_id: item.product_id,
                productCombo: item.productCombo,
                isCombo: item.isCombo,
                cost_price: item.cost_price
            }

            if (row.productCombo) {
                const hasMismatchedItems = Object.keys(row.productCombo).length > 0 && row.productCombo.some(item => {
                    return !products.some(product => item.item_id === product.product_id);
                });

                if (hasMismatchedItems) {
                    return message.error('The following products are not assigned to your branch. Please contact the system admin.');
                }
            }

            addItem(row, quantity);
        }
    }

    const options = products.map((item) => ({
        value: item.name,
        label: item.name,
        sku: item.sku,
        product: item
    }));

    const handleSearch = (value) => {
        if (value === '') {
            setFilteredData([]);
        } else {
            const filtered = options.filter(
                (option) =>
                    option.label.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
                    option.sku.toLowerCase().indexOf(value.toLowerCase()) !== -1
            );
            setFilteredData(filtered);
        }
    };

    useEffect(() => {
        setCurrency(localStorage.getItem('currency'))
    }, [])


    return (
        <>
            <Card style={{ width: "97%" }}>
                {/* category */}
                {children}
                {/* search Bar */}
                <div style={{ margin: '10px 0px 10px 0px' }}>
                    <AutoComplete
                        allowClear
                        options={filteredData}
                        value={input}
                        style={{ width: '100%' }}
                        onSearch={handleSearch}
                        placeholder="Search Product Name / SKU"
                        onChange={(e) => {
                            search({ name: e, type: 1 });
                            setInput(e);
                        }}
                        onSelect={(e, option) => {
                            let item = option.product;
                            addToCart(item, 1);
                            setInput('');
                        }}
                    />
                </div>

                <div style={{ overflow: 'auto', height: '500px' }}>
                    <List
                        locale={{
                            emptyText: loading === true ?
                                <Spin style={{ marginTop: 100 }} spinning={loading} size="large" tip="Please wait..." />
                                :
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
                        }}
                        grid={{ gutter: 10, column: products[0]?.image === null ? 2 : 4 }}
                        dataSource={products}
                        renderItem={(item) => {
                            return (
                                <List.Item
                                    style={{ cursor: "pointer" }}
                                    key={item.id}
                                    onClick={() => {
                                        // if (item.stock === 0 || !item.stock) return;
                                        addToCart(item, 1);
                                    }}>
                                    {item.image !== null ? (
                                        <Card bodyStyle={{ padding: '5px' }}>
                                            <Image height='auto' width='100%' src={item.image} preview={false} />
                                            <br />
                                            <div style={{ overflow: 'hidden', height: '48px' }}>
                                                <Text style={{ fontWeight: 'bold', fontSize: 16, lineHeight: '1px' }}>{item.name}</Text>
                                            </div>
                                            <Text><i>{currency} {item.price}</i></Text>
                                            <Button style={{ width: '100%' }} type="primary" key={item.id}>Add To Cart</Button>
                                        </Card>
                                    ) : (
                                        <Card bodyStyle={{ padding: '10px' }}>
                                            <Text style={{ fontWeight: 'bold', fontSize: 16 }}>{item.name}</Text>
                                            <br />
                                            <div style={{ textAlign: "right" }}>
                                                <Text><i style={{ display: "inline-block" }}> {currency} {item.price}</i></Text>
                                            </div>
                                        </Card>
                                    )
                                    }
                                </List.Item>
                            )
                        }}
                    />
                </div>
            </Card >
        </>
    )
}

export default Products