import { Badge, Button, Card, Space } from "antd"

const Cart = ({ modal, invoiceModal, discard, setKitchenModal, kitchenCount, count, enableCartParking, children }) => (
    <Card>
        <Space style={{ width: '100%', display: 'flex', justifyContent: "space-between" }}>
            <Space>

                <div style={{ marginRight: 7 }}>
                    <Badge color="secondary" count={kitchenCount} overlap="circle">
                        <Button style={{ background: '#bc6309', color: 'white' }} onClick={() => { setKitchenModal(true) }}>Kitchen</Button>
                    </Badge>
                </div>

                {
                    enableCartParking &&
                    <div style={{ marginRight: 7 }}>
                        <Badge count={count}>
                            <Button type="primary" onClick={() => modal('resume')}>Resume (r)</Button>
                        </Badge>
                    </div>
                }

                <Button type="primary" danger onClick={discard}>Discard (d)</Button>
            </Space>
            <Button style={{ background: '#003366', color: 'white' }} onClick={() => invoiceModal(true)}>Previous Invoice (i)</Button>
        </Space>
        {children}

    </Card>
)

export default Cart