import { Col, Divider, Input, Row } from "antd"
import TextArea from "antd/lib/input/TextArea"
import { useCart } from "react-use-cart";

const CartItemsSummery = ({
    amountDiscount,
    setAmountDiscount,
    percentageDiscount,
    setPercentageDiscount,
    shippingCharges,
    setShippingCharges,
    setNotes,
    subtotal,
    setSubtotal,
    cartSummaryTotal,
    setCartSummaryTotal,
    setDiscount,
    enableDiscount,
    srb
}) => {

    const { items } = useCart();

    // setTotalDiscounts(items.reduce((acc, item) => acc += parseInt(item.rowDiscountAmount), 0))

    setSubtotal(items.reduce((acc, item) => acc += item.rowSubtotal, 0))

    let discountAmount = percentageDiscount ? (percentageDiscount / 100) * subtotal : amountDiscount;

    setDiscount(discountAmount)

    let totalAfterDiscount = subtotal - discountAmount;

    let total = totalAfterDiscount + (shippingCharges ? parseInt(shippingCharges) : 0);

    setCartSummaryTotal(total)

    const renderTaxRow = (total) => {

        if (Object.keys(srb).length > 0 && srb.settings) {

            let taxRate = parseFloat(srb.settings?.tax_rate);
            let isInclusive = srb.settings.tax_type == 'inclusive';
            let totalTax = isInclusive ? total - (total / (1 + (taxRate / 100))) : total * (taxRate / 100);

            return (
                <>
                    <Col span={5}>Tax ({isInclusive ? 'Inclusive' : 'Exclusive'})</Col>
                    <Col span={13}></Col>
                    <Col span={4} style={{ textAlign: 'center' }}>{(totalTax).toFixed(2)}</Col>
                    <Divider />
                </>
            )
        }
    }

    return (
        <Row>
            <Col span={5}>Subtotal</Col>
            <Col span={13}></Col>
            <Col span={4} style={{ textAlign: 'center' }}>{subtotal.toFixed(2)}</Col>
            <Divider />

            {renderTaxRow(total)}
            {
                enableDiscount &&
                <>
                    <Col span={5}>Discount</Col>
                    <Col span={1}></Col>
                    <Col span={7}>
                        <Input placeholder="Percentage" type="number" step={0.01}
                            disabled={amountDiscount.length > 0 ? true : false}
                            onChange={(e) => setPercentageDiscount(e.target.value)} />
                    </Col>
                    <Col span={1}></Col>
                    <Col span={7}>
                        <Input placeholder="Amount" type="number" step={0.01}
                            disabled={percentageDiscount.length > 0 ? true : false}
                            onChange={(e) => setAmountDiscount(e.target.value)} />
                    </Col>
                    <Divider />
                </>
            }
            <Col span={5}>Shipping Charges</Col>
            <Col span={11}></Col>
            <Col span={5}>
                <Input type="number" step={0.01} onChange={(e) => setShippingCharges(e.target.value)} />
            </Col>
            <Divider />
            <Col span={5}>Total</Col>
            <Col span={13}></Col>
            <Col span={4} style={{ textAlign: 'center' }}>{(cartSummaryTotal).toFixed(2)}</Col>
            <Divider />
            <Col span={5}>Notes</Col>
            <Col span={17}><TextArea onChange={(e) => setNotes(e.target.value)}></TextArea></Col>
        </Row>
    )
}
export default CartItemsSummery