
export const DOMAIN = process.env.REACT_APP_DOMAIN;

export const PUSHER_APP_KEY = process.env.REACT_APP_PUSHER_APP_KEY;
export const PUSHER_APP_ID = process.env.REACT_APP_PUSHER_APP_ID;
export const PUSHER_APP_SECRET = process.env.REACT_APP_PUSHER_APP_SECRET;
export const PUSHER_APP_CLUSTER = process.env.REACT_APP_PUSHER_APP_CLUSTER;

export const WEBSOCKET_HOST = process.env.REACT_APP_WEBSOCKET_HOST;
export const WEBSOCKET_PORT = process.env.REACT_APP_WEBSOCKET_PORT;

export const WEBSOCKET_URL = `${process.env.REACT_APP_WEBSOCKET_PROTOCOL}://${WEBSOCKET_HOST}:${WEBSOCKET_PORT}/socket`;

export const PAGINATION_OPTIONS = {
    defaultPageSize: 50,
    showSizeChanger: true,
    pageSizeOptions: ['10', '25', '50', '100', '200', '500', '1000']
}

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
export const ALEF_CLIENT_ID = process.env.REACT_APP_ALEF_CLIENT_ID;
export const ALEF_CLIENT_SECRET = process.env.REACT_APP_ALEF_CLIENT_SECRET;
export const ALEF_URL = process.env.REACT_APP_ALEF_URL;
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;