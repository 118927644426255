// import useAsyncStorageState from './use-async-storage';
import { PUSHER_APP_KEY, WEBSOCKET_URL } from '../constants';

const useWebSocket = () => {

    const store_id = localStorage.getItem('store_id')
    // const [userToken] = useAsyncStorageState('userToken');

    function socketHandler(endpoint, data, callback) {

        const pusherAppKey = PUSHER_APP_KEY;
        const url = WEBSOCKET_URL;
        const socket = new WebSocket(`${url}/${endpoint}?appKey=${pusherAppKey}`);

        socket.onopen = () => {
            data = { ...data, store_id: store_id }
            socket.send(JSON.stringify(data)); // send a message
            // console.log('Message sent', data);
        };

        socket.onmessage = (e) => {
            let data = e.data.replace(/\\/g, '');
            // console.log('Message received',data);
            if (data && data != "") {
                callback(JSON.parse(data)?.payload)
            }
        };

    }

    return { socketHandler };
}

export default useWebSocket;