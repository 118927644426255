import { useEffect, useState } from "react";
import { Button, Modal, Divider, Typography, Radio, Checkbox, Row, Col, InputNumber, message } from "antd"
import { useCart } from "react-use-cart";
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const CounterComponent = ({ counter, setCounter }) => {

    const handleIncrement = () => {
        setCounter(counter + 1);
    };

    const handleDecrement = () => {
        if (counter > 0) {
            setCounter(counter - 1);
        }
    };

    return (
        <div style={{ textAlign: 'left' }}>
            <Text style={{ marginRight: 10 }}>Quantity</Text>
            <Button onClick={handleDecrement}><MinusOutlined /></Button>
            <InputNumber min={1} max={10} value={counter} />
            <Button onClick={handleIncrement}><PlusOutlined /></Button>
        </div>
    );
};

const AddonModal = ({ visible, toggle, product }) => {

    const { addItem } = useCart();
    const [selectedOptions, setSelectedOptions] = useState([])
    const [selectedAddons, setSelectedAddons] = useState([])
    const [total, setTotal] = useState(0)
    const currency = localStorage.getItem('currency')
    const [counter, setCounter] = useState(1);

    const reset = () => {

        // if(selectedAddons){
        //     let newAddons = selectedAddons.map(addon => {
        //         return addon.children.map(child => {
        //             child.selected = false;
        //             return child
        //         })
        //     })
    
        //     setSelectedAddons([...newAddons])
        // }

        // if(selectedOptions){
            
        //     let newOptions = selectedOptions.map(addon => {
        //         return addon.children.map(child => {
        //             child.selected = false;
        //             return child
        //         })
        //     })
            
        //     setSelectedOptions([...newOptions])
        // }

        setCounter(1)
        setTimeout(() => {
            setTotal(product.price)
        },500)
    }

    const addToCart = (item, quantity = 1) => {

        let price = parseFloat(product.price)

        if(selectedAddons.length > 0){
            selectedAddons.map(addon => {
                addon.children.map(child => {
                    if (child.selected) {
                        price += parseFloat(child.price)
                    }
                })
            })
        }

        if(selectedOptions.length > 0){
            selectedOptions.map(addon => {
                addon.children.map(child => {
                    if (child.selected) {
                        price += parseFloat(child.price)
                    }
                })
            })
        }

        const d = new Date();
        let time = d.getTime();

        let row = {
            id: time,
            identifier : item.id,
            name: item.name,
            price: price,
            quantity,
            product_id: item.product_id,
            productCombo: item.productCombo,
            isCombo: item.isCombo,
            cost_price: item.cost_price,
            addons: selectedAddons,
            options: selectedOptions
        }

        // if (row.productCombo) {
        //     const hasMismatchedItems = Object.keys(row.productCombo).length > 0 && row.productCombo.some(item => {
        //         return !products.some(product => item.item_id === product.product_id);
        //     });

        //     if (hasMismatchedItems) {
        //         return message.error('The following products are not assigned to your branch. Please contact the system admin.');
        //     }
        // }

        addItem(row, quantity);

        toggle(false)
    }

    const recalculateTotal = () => {

        if(product){
            let price = parseFloat(product.price)
    
            selectedAddons.map(addon => {
                addon.children.map(child => {
                    if (child.selected) {
                        price += parseFloat(child.price)
                    }
                })
            })
    
            selectedOptions.map(addon => {
                addon.children.map(child => {
                    if (child.selected) {
                        price += parseFloat(child.price)
                    }
                })
            })
    
            price = counter * price;
    
            setTotal(price)
        }
    }

    useEffect(() => {
        recalculateTotal()
    }, [counter])

    useEffect(() => {
        if (product) {
            const sides = JSON.parse(product.addons)
            const { addons, options } = sides;

            if(addons){
                addons.map(addon => {
                    addon.selected = false;
                    addon.children.map(child => {
                        child.selected = false;
                        return child;
                    })
                    return addon;
                })
            }

            if(options){
                options.map(option => {
                    option.selected = false;
                    option.children.map(child => {
                        child.selected = false;
                        return child;
                    })
                    return option;
                })
            }

            if(options && options.length > 0){
                setSelectedOptions([...options]);
            }

            if(addons && addons.length > 0){
                setSelectedAddons([...addons]);
            }

            setTotal(product.price)
        }
    }, [product,visible])

    const handleOptionChange = (id, option) => {

        let newOptions = selectedOptions.map((opt) => {
            if (opt.key === option.key) {
                opt.children = opt.children.map(child => {
                    if (child.id === id) {
                        child.selected = true
                    } else {
                        child.selected = false
                    }

                    return child;
                })
                opt.selected = true;
            }
            return opt;
        })

        setSelectedOptions([...newOptions])
        recalculateTotal()
    }

    const handleAddonsChange = (ids, addon) => {

        let newAddons = selectedAddons.map((opt) => {
            if (opt.key === addon.key) {
                opt.children = opt.children.map(child => {
                    if (ids.includes(child.id)) {
                        child.selected = true
                    } else {
                        child.selected = false
                    }

                    return child;
                })

                opt.selected = opt.children.filter(obj => obj.selected === true).length == opt.limit;
            }
            return opt;
        })

        setSelectedAddons([...newAddons])
        recalculateTotal()
    }

    // const handleAddonCheck = (id, addon) => {
    //     console.log(id,addon)
    //     return false;
    // }

    const renderOptions = (options) => {
        if (options && options.length > 0) {
            return options.map((option, index) => {
                return (
                    <div key={index} style={{ marginTop: 20, marginBottom: 20 }}>
                        <Title level={5}>{option.title}</Title>
                        <Radio.Group name={`g-${index}`} onChange={(e) => handleOptionChange(e.target.value, option)} style={{ width: '100%' }}>
                            <Row>
                                {
                                    option.children.map((child, key) => {
                                        return (
                                            <Col span={8}>
                                                <Radio value={child.id} key={key}>{child.item} ({currency} {child.price})</Radio>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                            <Divider />
                        </Radio.Group>
                    </div>
                )
            })
        }
    }

    const renderAddons = (addons) => {
        if (addons && addons.length > 0) {
            return addons.map((addon, index) => {
                return (
                    <div key={index} style={{ marginTop: 20, marginBottom: 20 }}>
                        <Title level={5}>{addon.title} (select upto {addon.limit})</Title>
                        <Checkbox.Group onChange={(e) => handleAddonsChange(e, addon)} style={{ width: '100%' }}>
                            <Row>
                                {
                                    addon.children.map((child, index) => {
                                        return (
                                            <Col span={8}>
                                                <Checkbox checked={child.selected} value={child.id} key={index}>
                                                    {child.item + ` (${currency} ${child.price})`}
                                                </Checkbox>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Checkbox.Group>
                        <Divider />
                    </div>
                )
            })
        }
    }

    if (!product) {
        return null;
    }

    return (
        <Modal
            width={"50%"}
            title={`${product.name} (${parseFloat(total).toFixed('2')})`}
            afterClose={reset}
            destroyOnClose={true}
            centered
            open={visible}
            onCancel={() => toggle(false)}
            footer={
                <Row>
                    <Col span={12}>
                        <CounterComponent counter={counter} setCounter={setCounter} />
                    </Col>
                    <Col span={4}>
                    </Col>
                    <Col span={8}>
                        <Button key="back" type="primary" onClick={() => addToCart(product,counter)}>Confirm</Button>
                    </Col>
                </Row>
            }
        >
            {renderOptions(selectedOptions)}
            {renderAddons(selectedAddons)}
        </Modal >
    )
}

export default AddonModal
