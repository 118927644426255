import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { DOMAIN, PUSHER_APP_CLUSTER, PUSHER_APP_KEY, WEBSOCKET_HOST, WEBSOCKET_PORT } from '../constants';

const websocketService = () => {

    async function init() {

        try {
            return new Echo({
                broadcaster: 'pusher',
                key: PUSHER_APP_KEY,
                cluster: PUSHER_APP_CLUSTER,
                encrypted: false,
                wsHost: WEBSOCKET_HOST,
                wsPort: WEBSOCKET_PORT,
                forceTLS: false,
                disableStats: true,
                authEndpoint: `${DOMAIN}/api/broadcasting/auth`,
                auth: {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            });

        } catch (e) {
            console.log(e)
        }
    }

    return init();
}

export default websocketService;
