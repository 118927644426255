import { ajaxService, urlService } from "../services";
import { Response } from "../utility";

const setDependency = () => { ajaxService.setDependency() }

export async function saveParkSale(data) {
    setDependency()
    return ajaxService.post(urlService.saleScreen, data).then(response => {
        return Response(response.data);
    })
}

export async function getAllParkSale(id) {
    setDependency()
    return ajaxService.get(urlService.saleScreen + '/' + id).then(response => {
        return Response(response.data);
    })
}

export async function updateParkSale(id, data) {
    return ajaxService.put(urlService.saleScreen + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function PlaceOrder(data) {
    setDependency()
    return ajaxService.post(urlService.placeOrder, data).then(response => {
        return Response(response.data);
    })
}

export async function cashRegisterOrder(id) {
    setDependency()
    return ajaxService.get(urlService.cashRegisterOrder + '/' + id).then(response => {
        return Response(response.data);
    })
}
