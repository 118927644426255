import { Button, Col, Divider, Input, Row, Space, Typography } from "antd"
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { useCart } from "react-use-cart";
import { useEffect } from "react";

const { Text } = Typography
const CartItemsRow = ({ item, openModal, products, editableUnitPrice }) => {

    const { removeItem, updateItemQuantity, updateItem } = useCart();

    let discount = 0

    if (item.discountRow) {
        discount = item.percentage ? (item.discountRow / 100) * item.itemTotal : item.perUnit ? (item.discountRow * item.quantity) : item.discountRow
    }

    let itemSubtotal = item.itemTotal - discount;

    useEffect(() => {
        updateItem(item.id, { rowSubtotal: itemSubtotal, rowDiscountAmount: discount })
    }, [itemSubtotal, products])

    const renderCombo = (row) => {
        const comboProducts = products.find(i => i.id === row.product_id)?.productCombo || [];
        return comboProducts.map(i => {
            const p = products.find(obj => obj.product_id === i.item_id);
            return (
                <Row style={{ marginLeft: 20, marginTop: 14 }}>
                    <Col span={5}>
                        <Text style={{ color: '#00CC66' }}>{p?.name}</Text>
                    </Col>
                    <Col span={4}>
                        <Text style={{ color: '#00CC66' }}>{i.quantity}</Text>
                    </Col>
                </Row>
            );
        });
    }

    const checkDisabled = () => {

        if(item && ((item.addons && item.addons.length > 0) || (item.options && item.options.length > 0))){
            return true;
        }

        return false

    }

    return (
        <>
            <Row>
                <Col span={4}>
                    <Text>{item.name}</Text>
                </Col>
                <Col span={1}></Col>
                <Col span={3} style={{ textAlign: 'center' }}>
                    <Input type="number" disabled={checkDisabled} onChange={(e) => updateItemQuantity(item.id, e.target.value)} value={item.quantity} />
                </Col>
                <Col span={1}></Col>
                <Col span={4} style={{ textAlign: 'center' }}>
                    <Input type="number" disabled={checkDisabled} step={0.01} onChange={(e) => updateItem(item.id, { price: e.target.value })} defaultValue={item.price} readOnly={!editableUnitPrice} />
                </Col>
                <Col span={4} style={{ textAlign: 'center' }}>
                    {item.discountRow}{item.percentage ? '%' : ''}
                </Col>
                <Col span={4} style={{ textAlign: 'center' }}>
                    {(itemSubtotal).toFixed(2)}
                </Col>
                <Col span={3}>
                    <Space>
                        <Button size="small" type="primary" icon={<EditOutlined />}
                            onClick={() => openModal(item.id)} />
                        <Button size="small" type="primary" danger icon={<CloseOutlined />}
                            onClick={() => removeItem(item.id)} />
                    </Space>
                </Col>
                <Col span={24}>
                    {renderCombo(item)}
                </Col>
            </Row>
            <Divider />
        </>
    )
}
export default CartItemsRow