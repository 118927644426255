import { Modal } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import audio from './audio';

const soundAlert = () => {
    Modal.info({
        title: 'Sound Alert',
        content: "Please click the 'OK' button to hide this alert; otherwise, this alert will not be dismissed.",
        icon: <SmileOutlined />,
        onOk() { audio() },
    });
}

export default soundAlert