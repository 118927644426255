import { useEffect, useState } from "react"
import { Button, Col, Divider, Form, Input, Modal, Row, message } from "antd"
import { useCart } from "react-use-cart";
import { PlaceOrder } from "../../../data";
import OrderStatus from "../../../constant/orderStatus";
import Limits from "../../../constant/storeLimits";
import moment from "moment";
import useOnlineStatus from "../../../hooks/useOnlineStatus";
import { getOrder, getOrderItems, insertOrderItems, insertOrders } from "../../../services/sql-service";

const SellModal = ({
    visible,
    toggle,
    customer,
    customerId,
    shipping,
    notes,
    salePrintModal,
    saleOrder,
    saleOrderItems,
    subtotal,
    formData,
    setFormData,
    setKitchenButtons,
    waiters,
    tables,
    setCustomerId,
    branch_id,
    orderId,
    setOrderId,
    cartSummaryTotal,
    discount,
    setDiscount,
    setAmountDiscount,
    setPercentageDiscount,
    setShippingCharges,
    setNotes,
    setLoadScreen,
    store,
    db,
    srb,
    takeaway
}) => {

    const isOnline = useOnlineStatus();

    const storeID = localStorage.getItem('store_id');
    const branchID = localStorage.getItem('posBranchId');

    // For order limits
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const startDate = `${year}-${month}-01`;
    const todayDate = `${year}-${month}-${day}`;
    const [totalTax, setTotalTax] = useState(0)

    let orders = store?.orders ?? null;
    let storeRestriction = null;

    if (orders) {
        orders = orders.filter(i => moment(i.date).format('Y-M-DD') >= startDate && moment(i.date).format('Y-M-DD') <= todayDate);
        storeRestriction = store && store.store_restrictions ? store.store_restrictions : null;
    }

    const [sellingForm] = Form.useForm()
    const [pay, setPay] = useState(cartSummaryTotal)

    const { items, emptyCart } = useCart();

    let total = cartSummaryTotal

    let totalBalance = 0

    totalBalance = pay - total

    if (customer) {
        totalBalance = (total - pay) + parseInt(customer.balance != 0 ? customer.balance : 0)
    }

    const saleComplete = async (values) => {

        let amountPaid = sellingForm.getFieldValue().amount_paid;

        if (!formData || Object.keys(formData).length === 0) {
            return message.error('Before proceeding with the sale, please select one of the available actions (Dine in, Delivery, or Takeaway).')
        }

        const orderInfo = formData.json ? JSON.parse(formData.json) : formData
        const waiter = orderInfo.waiter ? waiters.find(i => i.id === orderInfo.waiter || i.name === orderInfo.waiter).name : null
        const table = orderInfo.table ? tables.find(i => i.id === orderInfo.table || i.name === orderInfo.table).name : null

        if (amountPaid < total && customerId === 0) {
            return message.error('Insufficient Amount Entered')
        }

        let data = {
            amount_paid: values.amount_paid,
            amount_changed: totalBalance,
            subtotal: subtotal,
            shipping: shipping,
            discount: discount,
            total: total,
            notes: notes,
            customer_id: customerId,
            name: customer ? customer.name : null,
            phone: customer ? customer.phone : null,
            cash_register_id: localStorage.getItem('cashRegister_id'),
            order_type: formData.orderType,
            total_guest: orderInfo.total_guest ?? null,
            table: table,
            waiter: waiter,
            address: orderInfo.address ?? null,
            time: orderInfo.time ?? null,
            branch_id: branch_id,
            is_grocery_pos: false,
            status: OrderStatus.COMPLETED,
            order_id: orderId,
            platform: 'POS',
            order_items: [],
        }

        let modifiedItems = items.map(item => {

            let addons = [];
            let options = [];

            if(item.addons && item.addons.length > 0){
                item.addons.map(addon => {
                    if(addon.children && addon.children.length > 0){
                        addon.children.map(addonItem => {
                            if(addonItem.selected){
                                let row = {
                                    title : addon.title,
                                    item : addonItem.item,
                                    price : addonItem.price,
                                    cost : addonItem.cost,
                                    type : 1,
                                    selected:true
                                }
        
                                addons.push(row);
                            }
                            return addonItem;
                        })
                    }
                    return addon;
                })
            }

            if(item.options && item.options.length > 0){

                item.options.map(option => {
                    if(option.children && option.children.length > 0){
                        option.children.map(optionItem => {
                            if(optionItem.selected){
                                let row = {
                                    title : option.title,
                                    item : optionItem.item,
                                    price : optionItem.price,
                                    cost : optionItem.cost,
                                    type : 0,
                                    selected:true                            
                                }
                                options.push(row)
                            }
                            return optionItem;
                        })
                    }
                    return option;
                })   
            }
            
            item.addons = addons;
            item.options = options;
            return item;                   
        })

        data.order_items = modifiedItems;

        // console.log(data);return;

        if (isOnline) {
            const orderData = await PlaceOrder(data)
            if (orderData) afterPlaceOrder(orderData);
        } else {
            const date = moment().format('YYYY-MM-DD HH:mm');
            let row = {
                temporary_order_id: date,
                order_number: 'ORDER' + moment().format('YYYYMMDDHHmm'),
                store_id: storeID,
                branch_id: branchID,
                name: data.name,
                phone: data.phone,
                customer_id: data.customer_id,
                date: date,
                status: data.status,
                amount_changed: data.amount_changed,
                amount_paid: data.amount_paid,
                discount: data.discount,
                shipping: data.shipping,
                subtotal: data.subtotal,
                cash_register_id: data.cash_register_id,
                is_grocery_pos: data.is_grocery_pos,
                notes: data.notes,
                platform: data.platform,
                total: data.total,
                address: data.address,
                order_id: data.order_id,
                order_type: data.order_type,
                order_table: data.table,
                time: data.time,
                total_guest: data.total_guest,
                waiter: data.waiter,
            }          

            const orderData = [null, null];

            let id = await insertOrders(db, row);

            if (id > 0) {

                data.order_items.map(i => {
                    i.order_id = id;
                    insertOrderItems(db, i)
                })

                let order = getOrder(db, id)

                srbCalculation(order)
                orderData[0] = order;

                let items = await getOrderItems(db, id);

                if (items) {

                    const rows = [];

                    items.forEach(i => {
                        let row = {
                            product_name: i.name,
                            selling_price: i.price,
                            quantity: i.quantity,
                            discount: i.rowDiscountAmount,
                            total: i.rowSubtotal,
                            product_id: i.product_id
                        };


                        if(i.addons){
                            row.addons = JSON.stringify(i.addons)
                        }
                        if(i.options){
                            row.options = JSON.stringify(i.options)
                        }                         

                        rows.push(row);
                    });

                    orderData[1] = rows;
                    afterPlaceOrder(orderData)
                }
            }
        }
    }

    const srbCalculation = (order) => {
        const { active, settings } = srb;

        if (active) {
            let total = parseInt(order.total);
            let netTotal = total;
            let taxRate = 0;
            let taxAmount = 0;

            if (settings) {
                if (settings.tax_type == "inclusive") {
                    netTotal = total / (1 + (settings.tax_rate / 100));
                    taxAmount = total - netTotal;
                }

                if (settings.tax_type == "exclusive") {
                    taxAmount = total * (settings.tax_rate / 100);
                    total += taxAmount;
                }

                taxRate = settings.tax_rate ?? 0;

                order.net_total = parseFloat(netTotal.toFixed(2));
                order.tax_rate = taxRate;
                order.tax_amount = parseFloat(taxAmount.toFixed(2));
                order.total = parseFloat(total.toFixed(2))
                console.log('Tax', order.tax_amount)
            }
        }
    }

    const afterPlaceOrder = (orderData) => {

        let rows = []

        let items = orderData[1];

        items.map(item => {

            let { additions } = item;

            if (additions &&  additions.length > 0) {
                let subItems = []

                additions.map(addition => {
                    subItems.push({
                        product_name: addition.title + ': ' + addition.item,
                        selling_price: addition.price,
                        quantity: item.quantity,
                        total: (parseFloat(item.quantity) * parseFloat(addition.price)).toFixed(2)
                    })
                });

                rows.push(item);

                rows = [...rows, ...subItems];
            }else{
                rows.push(item);
            }
        });        


        saleOrder(orderData[0])
        saleOrderItems(rows)
        salePrintModal(true)
        emptyCart()
        toggle(false)
        setKitchenButtons(2)
        setFormData(takeaway)
        sellingForm.resetFields()
        totalBalance = 0;
        setPay(0)
        setCustomerId(0)
        setOrderId(0)
        setDiscount(0)
        setAmountDiscount(0)
        setPercentageDiscount(0)
        setShippingCharges(0)
        setNotes('')
        // if (isOnline) {
        //     setLoadScreen(true)
        // }
    }

    const onClick = () => {

        if (orders) {

            
            let orderLimit = storeRestriction.find(i => i.type === Limits.POS_ORDER_LIMITS)?.value || 0;
            
            if (!storeRestriction || orderLimit == 0 || orderLimit >= orders.length) {
                sellingForm.submit()
            } else {
                Modal.error({
                    title: 'Exceeding Limits',
                    content: Limits.MESSAGE,
                })
            }
        } else {
            sellingForm.submit()
        }
    }

    useEffect(() => {

        if (Object.keys(srb).length > 0 && srb.settings) {

            let taxRate = parseFloat(srb.settings?.tax_rate);
            let isInclusive = srb.settings.tax_type == 'inclusive';
            let totalTax = isInclusive ? total - (total / (1 + (taxRate / 100))) : total * (taxRate / 100);

            setTotalTax(totalTax)
        }
    })

    useEffect(() => {
        setPay(cartSummaryTotal)
        sellingForm.setFieldValue('amount_paid',cartSummaryTotal)
    }, [cartSummaryTotal])

    return (
        <>
            <Form onFinish={saleComplete} form={sellingForm}>
                <Modal
                    width="40%"
                    title="Current Sale"
                    centered
                    open={visible}
                    onCancel={() => toggle(false)}
                    footer={[
                        <Button key="save" type="primary" onClick={onClick}>Complete</Button>,
                        <Button key="back" onClick={() => toggle(false)}>Close</Button>
                    ]}>
                    <Row>
                        <Col span={14}>Customer</Col>
                        <Col span={10} style={{ textAlign: 'right' }}>{customer ? customer.name : "Anonymous"}</Col>
                        <Divider style={{ marginTop: 10 }} />

                        <Col span={14}>Phone</Col>
                        <Col span={10} style={{ textAlign: 'right' }}>{customer ? customer.phone : "N/A"}</Col>
                        <Divider style={{ marginTop: 10 }} />

                        <Col span={14}>Balance</Col>
                        <Col span={10} style={{ textAlign: 'right' }}>{customer ? customer.balance : 0}</Col>
                        <Divider style={{ marginTop: 10 }} />

                        <Col span={14}>Subtotal</Col>
                        <Col span={10} style={{ textAlign: 'right' }}>{(subtotal).toFixed(2)}</Col>
                        <Divider style={{ marginTop: 10 }} />

                        <Col span={14}>Shipping</Col>
                        <Col span={10} style={{ textAlign: 'right' }}>{(shipping).toFixed(2)}</Col>
                        <Divider style={{ marginTop: 10 }} />

                        <Col span={14}>Discount</Col>
                        <Col span={10} style={{ textAlign: 'right' }}>{(parseInt(discount)).toFixed(2)}</Col>
                        <Divider style={{ marginTop: 10 }} />

                        <Col span={14}>Tax</Col>
                        <Col span={10} style={{ textAlign: 'right' }}>{totalTax.toFixed(2)}</Col>
                        <Divider style={{ marginTop: 10 }} />

                        <Col span={14}>Total</Col>
                        <Col span={10} style={{ textAlign: 'right' }}>{(total).toFixed(2)}</Col>
                        <Divider style={{ marginTop: 10 }} />

                        <Col span={14}>Pay</Col>
                        <Col span={10}>
                            <Form.Item name='amount_paid'
                                rules={[
                                    {
                                        required: customerId === 0 ? true : false, message: 'Payment is required'
                                    }
                                ]}
                            >
                                <Input onChange={(e) => setPay(e.target.value)} type="number" step={0.01} />
                            </Form.Item>
                        </Col>
                        <Divider style={{ marginTop: 10 }} />

                        <Col span={14}>Balance / Change</Col>
                        <Col span={10} style={{ textAlign: 'right' }}>
                            {(totalBalance).toFixed(2)}
                        </Col>
                    </Row>
                </Modal>
            </Form>
        </>

    )
}

export default SellModal