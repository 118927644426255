import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

// import {
//     AuthLayout,
//     MainLayout,
// } from '../screens';

import AuthLayout from '../layout/auth';
import MainLayout from '../layout';
import Order from '../pages/order';
import OrderForm from '../pages/order/order';
import Login from '../pages/auth/login';
import Profile from '../pages/user_profile';
import ProfileForm from '../pages/user_profile/form';
import ChangePasswordForm from '../pages/user_profile/changePassword';
import CashRegister from '../pages/cash_register';
import CashRegisterEntry from '../pages/cash_register/entry';
import CashReport from '../pages/cash_register/cash_report';
import CashReportDetails from '../pages/cash_register/view';
import SalesScreen from '../pages/sales_screen';

import { CartProvider } from "react-use-cart";
import UserBranches from '../pages/auth/branches';
import OrderPrint from '../pages/order/print';


const AuthRedirect = ({ destination }) => {
    return <Route render={(props) => (<Redirect to={{ pathname: destination, state: { from: props.location } }} />)} />;
}

const wrapper = (Layout, Component) => {

    let accessToken = localStorage.getItem('token');
    let branch_id = localStorage.getItem('posBranchId');

    const redirect = <AuthRedirect destination={'/login'}></AuthRedirect>

    if (accessToken == null) return redirect;

    if (branch_id == null) return <AuthRedirect destination={'/branches'}></AuthRedirect>

    return (
        <Layout>
            <Component />
        </Layout>
    )
}

export default function Navigation() {
    return (
        <CartProvider>
            <Router>
                <Route exact path={`/login`} component={() => <AuthLayout><Login /></AuthLayout>} />
                <Route exact path={`/branches`} component={() => <AuthLayout><UserBranches /></AuthLayout>} />
                <Route exact path={`/`} component={() => wrapper(MainLayout, SalesScreen)} />
                <Route exact path={`/cash-register`} component={() => wrapper(MainLayout, CashRegister)} />
                <Route exact path={`/cash-register/entries/:id?`} component={() => wrapper(MainLayout, CashRegisterEntry)} />
                <Route exact path={`/cash-reports`} component={() => wrapper(MainLayout, CashReport)} />
                <Route exact path={`/cash-reports/details/:id?`} component={() => wrapper(MainLayout, CashReportDetails)} />
                <Route exact path={`/orders`} component={() => wrapper(MainLayout, Order)} />
                <Route exact path={`/orders/form/:id?`} component={() => wrapper(MainLayout, OrderForm)} />
                <Route exact path={`/orders/print/:id?`} component={() => wrapper(MainLayout, OrderPrint)} />

                <Route exact path={`/profile`} component={() => wrapper(MainLayout, Profile)} />
                <Route exact path={`/profile/form/:id?`} component={() => wrapper(MainLayout, ProfileForm)} />
                <Route exact path={`/profile/change-password/form/:id?`} component={() => wrapper(MainLayout, ChangePasswordForm)} />
            </Router>
        </CartProvider>
    );
}