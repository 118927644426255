import { ajaxService, urlService } from "../services";
import { Response } from "../utility";

export async function getStore(id) {
    ajaxService.removeDependency()
    return ajaxService.get(urlService.stores + '/' + id).then(response => {
        if(response){
            return Response(response.data);
        }
        return null;
    })
}