import React, { useEffect, useState, useRef } from "react";
import { Button, Card, Col, Image, Row, Typography } from "antd";
import { useParams, useHistory } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { getOrder, getProducts, getStoreSetting } from "../../data";
import { ajaxService } from "../../services";
import moment from "moment";
import ControlSettings from "../../constant/controlSettings";
import QRCode from "react-qr-code";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { formatTime } from "../../utility";

const { Text } = Typography

const OrderPrint = () => {

    let { id } = useParams()
    let history = useHistory()

    const thermalRef = useRef();
    const a4Ref = useRef();
    const a5Ref = useRef();

    const [store, setStore] = useState({})
    const [order, setOrder] = useState({})
    const [branch, setBranch] = useState({})
    const [orderItems, setOrderItems] = useState([])
    const [products, setProducts] = useState([])
    const [displayBranchInfo, setDisplayBranchInfo] = useState(false);

    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [website, setWebsite] = useState('');
    const [disclaimer, setDisclaimer] = useState('');
    const [openingTime, setOpeningTime] = useState('');
    const [closingTime, setClosingTime] = useState('');

    const [srbToggle, setSrbToggle] = useState(false)
    const [forceRefresh, setForceRefresh] = useState(true)

    const storeSetting = async () => {
        const response = await getStoreSetting(ControlSettings.SRB_SETTINGS);
        if (response) setSrbToggle(response.active)

        try {
            const branchSettingResponse = await getStoreSetting(ControlSettings.RECEIPT_FORMAT);
            if (branchSettingResponse) {
                let { display_branch_info } = JSON.parse(branchSettingResponse.setting);
                setDisplayBranchInfo(display_branch_info)
            }
        } catch (e) {
            setDisplayBranchInfo(false)
        }
    }

    const loadProducts = async () => {
        const response = await getProducts(forceRefresh)
        if (response) {
            setProducts(response)
            setForceRefresh(false)
        }
    }

    const loadOrder = async () => {
        const response = await getOrder(id)

        if (response) {

            const { order, orderItems } = response

            setStore(order?.store || null);
            setOrder(order);
            setBranch(order?.branch || null);

            let rows = [];
            orderItems.map(orderItem => {
                rows.push(orderItem);

                if (orderItem.additions) {
                    let records = [];
                    orderItem.additions.map(addon => {
                        records.push({
                            total: addon.price * (1 * orderItem.quantity),
                            product_name: addon.title + ': ' + addon.item,
                            quantity: 1 * orderItem.quantity,
                            selling_price: addon.price
                        })
                    })

                    records.sort((a, b) => {
                        if (a.product_name < b.product_name) return -1;
                        if (a.product_name > b.product_name) return 1;
                        return 0;
                    });

                    rows = [...rows, ...records];
                }
            });

            setOrderItems(rows);
        }
    }

    useEffect(() => {
        if (store) {
            if (displayBranchInfo) {
                setName(branch.name)
            } else {
                setName(store.name)
            }
            setOpeningTime(formatTime(branch.opening_time))
            setClosingTime(formatTime(branch.closing_time))
            setDisclaimer(branch.disclaimer)
            setPhone(branch.phone)
            setAddress(branch.address)
            setWebsite(branch.website)
        }
    }, [store, branch, displayBranchInfo])

    useEffect(() => {
        loadOrder()
        // storeSetting()
        // loadProducts()
    }, [])


    const renderCombo = (row) => {

        let product = products.find(i => i.id === row.product_id);

        if (product && product.is_combo) {
            return ("(Combo)")
            //     return (
            //         product.product_combo.map(i => {
            //             let p = products.find(obj => obj.id === i.item_id)
            //             return (
            //                 <Row>
            //                     <Col span={12}>
            //                         <Text style={{ color: '#00CC66' }}>{p ? p.name : null}</Text>
            //                     </Col>
            //                     <Col span={4}>
            //                         <Text style={{ color: '#00CC66' }}>{i.quantity}</Text>
            //                     </Col>
            //                 </Row>
            //             )
            //         })
            //     )
        }
    }

    const renderItems = (type) => {
        if (type == "thermal") {
            return (
                <>
                    <Row style={{ padding: '0px 10px 0px 5px' }}>
                        <Col span={8} style={{ textAlign: 'center' }}>
                            <b>Price</b>
                        </Col>
                        <Col span={8} style={{ textAlign: 'center' }}>
                            <b> Quantity</b>
                        </Col>
                        <Col span={8} style={{ textAlign: 'right' }}>
                            <b>Total Price</b>
                        </Col>
                    </Row>
                    <hr />

                    {
                        orderItems.map(item => (
                            <>
                                <Row gutter={16} style={{ padding: '0px 10px 0px 5px', fontWeight: 'bold' }}>
                                    <Col span={24}>{item.product_name} {renderCombo(item)}</Col>
                                    <Col span={24}>
                                        <hr style={{ opacity: 0.4 }} />
                                    </Col>
                                    <Col span={8} style={{ textAlign: 'right' }}>{item.selling_price}</Col>
                                    <Col span={8} style={{ textAlign: 'center' }}>{item.quantity}</Col>
                                    <Col span={8} style={{ textAlign: 'right' }}>{item.total}</Col>
                                </Row>
                                <hr style={{ opacity: 0.7 }} />
                            </>
                        ))
                    }
                </>
            )
        }
        else {
            return (
                <>
                    <Row style={{ padding: '0px 10px 0px 5px' }}>
                        <Col span={7} style={{ textAlign: 'left' }}>
                            <b>Item</b>
                        </Col>
                        <Col span={4} style={{ textAlign: 'right' }}>
                            <b>Price</b>
                        </Col>
                        <Col span={5} style={{ textAlign: 'right' }}>
                            <b>Quantity</b>
                        </Col>
                        <Col span={4} style={{ textAlign: 'right' }}>
                            <b>Discount</b>
                        </Col>
                        <Col span={4} style={{ textAlign: 'right' }}>
                            <b>Total</b>
                        </Col>
                    </Row>
                    <hr />

                    {
                        orderItems.map(item => (
                            <>
                                <Row style={{ padding: '0px 10px 0px 5px' }}>
                                    <Col span={7} style={{ textAlign: 'left' }}>{item.product_name} {renderCombo(item)}</Col>
                                    <Col span={4} style={{ textAlign: 'right' }}>{item.selling_price}</Col>
                                    <Col span={5} style={{ textAlign: 'right' }}>{item.quantity}</Col>
                                    <Col span={4} style={{ textAlign: 'right' }}>{item.discount ? item.discount : 0}</Col>
                                    <Col span={4} style={{ textAlign: 'right' }}>{item.total}</Col>
                                </Row>
                                {/* {renderCombo(item)} */}
                                <hr style={{ opacity: 0.7 }} />
                            </>
                        ))
                    }
                </>
            )
        }
    }

    const renderSrbRows = (thermal) => (
        <>
            <Col span={15} style={{ fontWeight: thermal ? 'bold' : 'normal' }}>Total Befor Tax</Col>
            <Col span={9} style={{ textAlign: 'right', fontWeight: thermal ? 'bold' : 'normal' }}>{order.net_total ? order.net_total : 0}</Col>

            <Col span={12} style={{ fontWeight: thermal ? 'bold' : 'normal' }}>Tax {order.tax_rate}%</Col>
            <Col span={12} style={{ textAlign: 'right', fontWeight: thermal ? 'bold' : 'normal' }}>{order.net_total ? order.tax_amount : 0}</Col>
        </>
    )

    const renderContent = (type) => {

        let thermal = (type == "thermal")

        return (
            <>
                <div style={{ textAlign: 'center' }}>
                    <Image
                        width={50}
                        src={store ? (store.logo ? ajaxService.getImage(store.logo) : null) : null}
                        preview={false}
                        alt="Srore Logo"
                    />
                    <br />
                    <Text>{order.order_number}</Text>
                    <br />
                    <Text><b>{name}</b></Text>
                    <br />
                    <Text><b>{phone}</b></Text>
                    <br />
                    <Text><b>Shop Time: {openingTime} - {closingTime}</b></Text>
                    <br />
                    <Text><b>{website}</b></Text>
                </div>

                <div className="themeBtn" >
                    <p style={{ textAlign: 'center' }}>Receipt</p>
                </div>

                {/* Order Information */}
                <Row gutter={20}>

                    <Col span={thermal ? 13 : 6}><b>Customer</b></Col>
                    <Col span={thermal ? 10 : 6} style={{ textAlign: 'right', fontWeight: thermal ? 'bold' : 'normal' }}>{order ? (order.name ?? '') : "Anonymous"}</Col>

                    <Col span={thermal ? 13 : 6}><b>Phone</b></Col>
                    <Col span={thermal ? 10 : 6} style={{ textAlign: 'right', fontWeight: thermal ? 'bold' : 'normal' }}>{order ? (order.phone ?? '') : "N/A"}</Col>

                    <Col span={thermal ? 13 : 6}><b>Order</b></Col>
                    <Col span={thermal ? 10 : 6} style={{ textAlign: 'right', fontWeight: thermal ? 'bold' : 'normal' }}>{order ? (order.order_number ?? '') : "N/A"}</Col>

                    <Col span={thermal ? 13 : 5}><b>Date & Time</b></Col>
                    <Col span={thermal ? 10 : 7} style={{ textAlign: 'right', fontWeight: thermal ? 'bold' : 'normal' }}>{order ? moment(order.date).format('DD-MMM-yyy hh:mm a') : "N/A"}</Col>
                </Row>

                {/* Order Items Heading */}
                <div style={{ padding: 10 }}>
                    {renderItems(type)}
                </div>

                {/* <Divider /> */}

                <Row>
                    <Col span={24}>
                        <Row>
                            <Col span={12}>
                                <Row>
                                    {
                                        srbToggle &&
                                        <>
                                            <Col span={24}>
                                                <Image
                                                    width={thermal ? 70 : 50}
                                                    src={process.env.PUBLIC_URL + '/srb.png'}
                                                    preview={false}
                                                />
                                            </Col>
                                            <Col span={10} style={{ marginTop: 5 }}>
                                                <QRCode value={order.invoice_id ?? 'Default Value'} style={{ height: thermal ? 70 : 50, width: thermal ? 70 : 50 }} />
                                                <Text style={{ fontSize: 12 }}>{order.invoice_id}</Text>
                                            </Col>
                                        </>
                                    }
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>

                                    <Col span={12} style={{ fontWeight: thermal ? 'bold' : 'normal' }}>Subtotal</Col>
                                    <Col span={12} style={{ textAlign: 'right', fontWeight: thermal ? 'bold' : 'normal' }}>{order.subtotal ? order.subtotal : ''}</Col>

                                    <Col span={12} style={{ fontWeight: thermal ? 'bold' : 'normal' }}>Shipping</Col>
                                    <Col span={12} style={{ textAlign: 'right', fontWeight: thermal ? 'bold' : 'normal' }}>{order.shipping ? order.shipping : 0}</Col>

                                    <Col span={12} style={{ fontWeight: thermal ? 'bold' : 'normal' }}>Discount</Col>
                                    <Col span={12} style={{ textAlign: 'right', fontWeight: thermal ? 'bold' : 'normal' }}>{order.discount ? order.discount : 0}</Col>

                                    {
                                        srbToggle &&
                                        renderSrbRows(thermal)
                                    }

                                    <Col span={15} style={{ fontWeight: thermal ? 'bold' : 'normal' }}>Total {srbToggle ? "After Tax" : ''}</Col>
                                    <Col span={9} style={{ textAlign: 'right', fontWeight: thermal ? 'bold' : 'normal' }}>{order.total ? order.total : ''}</Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <div className="themeBtn">
                    <p style={{ textAlign: 'center' }}>Address</p>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <p><b>{address}</b></p>
                    <Text><b>{disclaimer}</b></Text>
                    <br />
                    <Text><b>Thankyou for shopping with us</b></Text>
                </div>
            </>
        )
    }

    const thermalContent = (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 10px' }}>

            <ReactToPrint
                trigger={() => <Button type="primary" style={{ marginBottom: '10px' }}>Print receipt in 3*width</Button>}
                content={() => thermalRef.current}
            />

            <Card style={{ width: '370px' }}>
                <div ref={thermalRef} style={{ margin: 20, fontSize: "11px" }}>
                    {renderContent('thermal')}
                </div>
            </Card>
        </div>
    )

    const A5Content = (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 10px' }}>

            <ReactToPrint
                trigger={() => <Button type="primary" style={{ marginBottom: '10px' }}>Print receipt in A5 size</Button>}
                content={() => a5Ref.current}
            />

            <Card style={{ width: '500px' }}>
                <div ref={a5Ref} style={{ margin: 20, fontSize: "8px" }}>
                    {renderContent("a5")}
                </div>
            </Card>
        </div>
    )

    const A4Content = (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 10px' }}>

            <ReactToPrint
                trigger={() => <Button type="primary" style={{ marginBottom: '10px' }}>Print receipt in A4 size</Button>}
                content={() => a4Ref.current}
            />

            <Card style={{ width: '600px' }}>
                <div ref={a4Ref} style={{ margin: 20, fontSize: '10px' }}>
                    {renderContent("a4")}
                </div>
            </Card>
        </div>
    )

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                <Button type="primary" icon={<ArrowLeftOutlined />} onClick={() => history.goBack()}>Back</Button>
            </div>
            <div style={{ display: 'flex' }}>
                {thermalContent}
                {A5Content}
                {A4Content}
            </div>
        </>
    )

}

export default OrderPrint