import { useEffect, useRef, useState } from "react";
import { useCart } from "react-use-cart";
import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Form, notification, Row, Space, Spin } from "antd";
import windowSize from "react-window-size";
import useWebSocket from "../../utility/use-websocket";
import { getAllParkSale, saveCustomer, saveParkSale, updateParkSale, getStore, getStoreSettings, getFeatured } from "../../data";
import { FormModalFields, ResumedDiscardFields } from "./modal_fields";
import Products from "./cart_products/product";
import Categories from "./cart_products/categories";
import { useHistory } from "react-router-dom";
import { Cart, Customers, EmptyCartView, CartFooter, CartItems, CartItemsRow, CartItemsSummery } from "./cart";
import Echo from "laravel-echo"

import {
    EditCartItemModal,
    FormModal,
    PreviousInvoiceModal,
    ResumedDiscardModal,
    SalePrintModal,
    SellModal,
    DineInModal,
    DeliveryModal,
    TakeawayModal,
    KitchenModal,
    AddonModal
} from "./modals";
import moment from "moment";
import { ajaxService, syncService, websocketService } from "../../services";
import audio from "../../layout/alertSound/audio";
import {
    filterProducts,
    getProductByProductID,
    initializeDb,
    insertProducts,
    truncateProductCombo,
    truncateProducts,
    updateProduct,
    filterProductsByCategoryID,
    filterProductsByKeyword,
    getProductsByProductID
} from "../../services/sql-service";
import ControlSettings from "../../constant/controlSettings";
import { useDebounce, useOnlineStatus } from "../../hooks";
import { PUSHER_APP_CLUSTER, PUSHER_APP_KEY, WEBSOCKET_HOST, WEBSOCKET_PORT } from "../../constants";

const timeFormat = "HH:mm:ss";
const currentTime = moment().format(timeFormat)

function SalesScreen() {

    const db = initializeDb();

    const isOnline = useOnlineStatus();

    let history = useHistory()
    const componentRef = useRef();
    const [form] = Form.useForm();
    const branch_id = localStorage.getItem('posBranchId')
    const store_id = localStorage.getItem('store_id')

    // Local Storage
    let cashRegisterId = localStorage.getItem('cashRegister_id')

    // Use Cart
    const { items, emptyCart, setItems, cartTotal } = useCart();
    const { socketHandler } = useWebSocket()

    // Checkbox
    const [parentChecked, setParentChecked] = useState(false);
    const [childValue, setChildValue] = useState(1);

    // Modals
    const [showModal, setShowModal] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [showEditItemModal, setShowEditItemModal] = useState(false)
    const [showSellModal, setShowSellModal] = useState(false)
    const [showInvoiceModal, setShowInvoiceModal] = useState(false)
    const [showSalePrintModal, setShowSalePrintModal] = useState(false)
    const [dineInModal, setDineInModal] = useState(false)
    const [deliveryModal, setDeliveryModal] = useState(false)
    const [takeawayModal, setTakeawayModal] = useState(false)
    const [kitchenModal, setKitchenModal] = useState(false)
    const [addonModal, setAddonModal] = useState(false)

    // Customers
    const [customers, setCustomers] = useState([]);
    const [customerId, setCustomerId] = useState(0);
    const [customer, setCustomer] = useState({})

    // Products
    const [products, setProducts] = useState([]);
    const [clickedProduct, setClickedProduct] = useState()

    // CartHooks
    const [itemsPercentageDiscount, setItemsPercentageDiscount] = useState(0);
    const [itemsAmountDiscount, setItemsAmountDiscount] = useState(0);
    const [shippingCharges, setShippingCharges] = useState(0);
    const [actionType, setActionType] = useState('');
    const [resumedData, setResumedData] = useState([]);
    const [cartItem, setCartItem] = useState('');
    const [notes, setNotes] = useState('')
    const [cartSubtotal, setCartSubtotal] = useState(0)
    const [saleOrder, setSaleOrder] = useState([])
    const [saleOrderItems, setSaleOrderItems] = useState([])

    // Store
    const [store, setStore] = useState({
        branches: [
            {
                address: '',
                phone: '',
                disclaimer: '',
                website: '',
            }
        ]
    })

    // Kitchen
    const json = JSON.stringify({ time: moment(currentTime, timeFormat).format(timeFormat) })
    const takeaway = { json, orderType: 2 }
    const [formData, setFormData] = useState(takeaway)
    const [kitchenButtons, setKitchenButtons] = useState(2)
    const [staffs, setStaffs] = useState([])
    const [tables, setTables] = useState([])
    const [orders, setOrders] = useState([])
    const [orderId, setOrderId] = useState(0)
    const [featured, setFeatured] = useState([]);
    const [cartSummaryTotal, setCartSummaryTotal] = useState(0)
    const [discount, setDiscount] = useState(0)

    const [loadScreen, setLoadScreen] = useState(false)
    // For spin
    const [loading, setLoading] = useState(true)

    // Store Settings
    const [posSetting, setPosSetting] = useState({
        print_receipt: 'thermal',
        editable_unit_price: false,
        enable_discount: false,
        enable_item_discount: false,
        cart_parking: false,
        verify_quantity: false,
        auto_adjust_quantity: false
    });
    const [srb, setSrb] = useState({})

    const [filterData, setFilterData] = useState({ category: null, name: null })

    // Refresh syncing
    const [refreshSync, setRefreshSync] = useState(false)

    const onFinish = async (values) => {
        if (actionType === 'holdItems') {
            let data = {
                description: values.description,
                customerId: customerId,
                cartItems: items,
                cashRegisterId: cashRegisterId,
                orderInfo: formData.json,
                orderType: formData.orderType,
            }

            const response = await saveParkSale(data)
            setShowModal(false)
            if (response) {
                loadResumedData();
                emptyCart()
                setItemsPercentageDiscount(0)
                setItemsAmountDiscount(0);
                setShippingCharges(0)
                setCustomerId(0)
                setKitchenButtons(3)
                setFormData({})
                form.resetFields()
                notification.open({ message: 'Items has been hold', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to hold items', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        } else {
            const response = await saveCustomer(values)
            
            if (response) {
                setCustomers([...customers , {...values , id : response.id}])
                setCustomerId(response.id); 
                setShowModal(false);
                notification.open({ message: 'Customer has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to add customer. Duplicate phone number', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const handleSearch = async (value, type) => {

        if (type === 1) {
            const { category, name } = filterData;
            const categoryId = category ?? null;
            const productName = name && name !== '' ? name : null;
            let response = await filterProducts(db, categoryId, productName)
            setProducts(response)
        } else if (type == 2) {
            setFilterData({ name: null, category: value })
        }

        getProductsFromWebSql();
    }

    useDebounce(handleSearch, 500, [filterData.name, filterData.category])

    const getCustomer = () => {
        let data = customers.find(i => i.id === customerId)
        setCustomer(data)
    }

    useEffect(() => {
        getCustomer()
    },[customerId])

    const openFormModal = (type) => {
        setActionType(type)
        if (customer)
            form.setFieldsValue({ description: customer.name + ' ' + customer.phone })
        setShowModal(true)
    }

    const openModal = (type) => {
        setActionType(type)
        setShowConfirmModal(true)
    }

    const loadResumedData = async () => {
        let data = await getAllParkSale(1)
        console.log(data)
        if (data) {
            setResumedData(data)
        }
    }

    const loadStore = async (id) => {
        const data = await getStore(id)
        if (data) {
            setStore(data)
        }
    }

    const renderCartProducts = async (items) => {

        truncateProductCombo(db);
        truncateProducts(db);

        for (const item of items) {
            if (item) {
                const row = {
                    id: item.product_id,
                    product_id: item.product_id,
                    name: item.product_name,
                    price: item.selling_price,
                    image: item.image ? item.image : null,
                    isCombo: item.is_combo ? 1 : 0,
                    category_id: item.category_id,
                    cost_price: item.cost_price,
                    showInPos: item.show_in_pos,
                    sku: item.sku,
                    addons: item.addons
                };

                if (item.show_in_pos) {

                    // For Combo
                    if (row.isCombo) {

                        for (const combo of item.product_combo) {
                            const response = await getProductByProductID(db, item.product_id, row.isCombo);
                            if (response.length > 0) {
                                await updateProduct(db, combo, combo.item_id, row.isCombo);
                            } else {
                                await insertProducts(db, combo, row.isCombo);
                            }
                        }

                    }

                    //For Simple
                    const response = await getProductByProductID(db, item.product_id, false);


                    if (row.isCombo) row.quantity = '';

                    if (response.length > 0) {
                        await updateProduct(db, row, item.product_id, false);
                    } else {
                        await insertProducts(db, row, false);
                    }
                }
            }
        };

        await getProductsFromWebSql();
    }

    /*
    const getProductsFromWebSql = async () => {

        let response = await getAllProducts(db);

        if (response.length > 0) {

            let items = [];

            for (let item in response) {

                if (item.isCombo === 1) {
                    let combo = await getAllProductCombo(db);
                    response[item].productCombo = combo.filter(x => x.product_id === item.product_id)
                }

                items.push(response[item]);
            }

            setProducts(items)
        } else {
            setProducts([])
        }

        setLoading(false)
    }
    */

    const getProductsFromWebSql = async () => {

        setLoading(true)
        // setProducts([])

        const { category, name } = filterData;
        let response = [];

        if (category > 0) {
            response = await filterProductsByCategoryID(db, category)
        } else if (name != null && name != '') {
            response = await filterProductsByKeyword(db, name)
        } else {
            response = await getProductsByProductID(db, featured);
        }

        setProducts(response)
        setLoading(false)
    }

    const listenToWhisper = async () => {
        const echo = await websocketService();
        const channel = echo.private(`store.${store_id}`)

        channel.listenForWhisper('order-ready', (e) => {
            audio()
            setOrders((prevOrders) => [...prevOrders, e.order]);
        });
    }

    const loadStoreSettings = async () => {
        setLoading(true)

        const response = await getStoreSettings();
        if (response) {
            const posFeature = response.find(i => i.slug === ControlSettings.ENABLE_POS);
            const srbFeature = response.find(i => i.slug === ControlSettings.SRB_SETTINGS);

            if (posFeature) {
                const posSettingData = posFeature.setting ? JSON.parse(posFeature.setting) : null;
                if (posSettingData) {
                    setPosSetting(prev => {
                        return { ...prev, ...posSettingData };
                    })
                };
            }

            if (srbFeature) {
                const srbData = { active: srbFeature.active ?? false, settings: srbFeature.setting ? JSON.parse(srbFeature.setting) : null }
                setSrb(srbData)
            }
        }
        setLoading(false)
    }

    const initialize = async () => {
        await loadStoreSettings()
        await loadFeatured()

        if (!cashRegisterId) {
            history.push({ pathname: "/cash-register", search: '?sales-screen' });
        }

        getCustomer()

        loadStore(store_id)
        // Resumed
        loadResumedData()
        // Customers
        socketHandler('customers', null, (response) => {
            setCustomers(response)
        });
        // Products
        if (isOnline) {
            socketHandler('branch-products', { branch_id: branch_id }, (response) => {
                if (response) {
                    renderCartProducts(response)
                }
            });
        } else {
            getProductsFromWebSql()
        }
        // staffs
        socketHandler('staffs', null, (response) => {
            const waiters = response.filter(i => i.role === 'Waiter')
            setStaffs(waiters)
        });
        // tables
        socketHandler('tables', null, (response) => {
            setTables(response)
        });
        // orders 
        socketHandler('orders', { branch_id: branch_id }, (response) => {
            const format = 'Y-MM-DD';
            const data = response.filter(i => i.status === "Ready" && moment(i.date).format(format) === moment().format(format))
            setOrders(data)
        });

        // listenToWhisper();

        setLoadScreen(false)
    }

    const broadcaster = async () => {

        const echo = new Echo({
            broadcaster: 'pusher',
            key: PUSHER_APP_KEY,
            cluster: PUSHER_APP_CLUSTER,
            wsHost: WEBSOCKET_HOST,
            wsPort: WEBSOCKET_PORT,
            disableStats: true,
            encrypted: true,
            forceTLS: true,
        });

        echo.channel('store').listen('OrderChanged', ({ order }) => {

            const branch_id = localStorage.getItem('posBranchId');
            const store_id = localStorage.getItem('store_id');

            if (order.store_id == store_id && branch_id == branch_id && order.status == 'Ready') {
                setOrders((prevOrders) => [...prevOrders, order]);
                audio()
            }
        })
    }

    useEffect(() => {
        broadcaster()
    }, [])

    const loadFeatured = async () => {
        setLoading(true)
        const response = await getFeatured();
        setFeatured(response)
        setLoading(false)
    }

    useEffect(() => {
        initialize()
    }, [loadScreen, featured.length])

    useEffect(() => {
        if (isOnline) {
            setRefreshSync(true)
            syncService(db, setRefreshSync)
        }
    }, [isOnline])

    const resumed = async (id, cartData, customer_id, order_info, order_type, type) => {
        setOrderId(id)
        setKitchenButtons(order_type)
        setCustomerId(customer_id)
        setFormData(JSON.parse(order_info))
        setItems(JSON.parse(cartData))
        setShowConfirmModal(false)
        if (type === "resumed") {
            let response = await updateParkSale(id, { resumed: 0 })
            if (response) {
                loadResumedData()
            }
        }
    }

    const discardItems = () => {
        emptyCart()
        setItemsPercentageDiscount(0)
        setItemsAmountDiscount(0)
        setShippingCharges(0)
        setShowConfirmModal(false)
        setCustomerId(0)
        setKitchenButtons(3)
        setFormData({})
        setChildValue(1)
        setParentChecked(false)
    }

    const openEditItemModal = (id) => {
        let item = items.find(i => i.id == id)
        setCartItem(item)
        setShowEditItemModal(true)
    }

    const renderChildComponents = () => {
        if (items != '') {
            return (
                <>
                    {/* Child */}
                    <CartItems>
                        {
                            items.map(item => (
                                <CartItemsRow
                                    item={item}
                                    openModal={openEditItemModal}
                                    products={products}
                                    editableUnitPrice={posSetting.editable_unit_price}
                                />
                            ))
                        }
                        <CartItemsSummery
                            amountDiscount={itemsAmountDiscount}
                            setAmountDiscount={setItemsAmountDiscount}
                            percentageDiscount={itemsPercentageDiscount}
                            setPercentageDiscount={setItemsPercentageDiscount}
                            shippingCharges={shippingCharges}
                            setShippingCharges={setShippingCharges}
                            setNotes={setNotes}
                            subtotal={cartSubtotal}
                            setSubtotal={setCartSubtotal}
                            cartSummaryTotal={cartSummaryTotal}
                            setCartSummaryTotal={setCartSummaryTotal}
                            setDiscount={setDiscount}
                            enableDiscount={posSetting.enable_discount}
                            srb={srb}
                        />

                    </CartItems>

                    <CartFooter
                        openModal={openFormModal}
                        openSellModal={setShowSellModal}
                        printOrder={printOrder}
                        shipping={shippingCharges}
                        notes={notes}
                        subtotal={cartSubtotal}
                        formData={formData}
                        setFormData={setFormData}
                        setKitchenButtons={setKitchenButtons}
                        waiters={staffs}
                        tables={tables}
                        setCustomerId={setCustomerId}
                        branch_id={branch_id}
                        customer={customer}
                        customerId={customerId}
                        kitchenButtons={kitchenButtons}
                        orderId={orderId}
                        setOrderId={setOrderId}
                        cartSummaryTotal={cartSummaryTotal}
                        discount={discount}
                        setDiscount={setDiscount}

                        setAmountDiscount={setItemsAmountDiscount}
                        setPercentageDiscount={setItemsPercentageDiscount}
                        setShippingCharges={setShippingCharges}
                        setNotes={setNotes}
                        store={store}
                        // POS Setting
                        enableCartParking={posSetting.cart_parking}
                    />
                </>
            )
        }
        else {
            return (
                <EmptyCartView />
            )
        }
    }

    const printOrder = () => {
        let rows = [];

        items.map(item => {

            let addons = [];
            let options = [];

            if (item.addons && item.addons.length > 0) {
                item.addons.map(addon => {
                    if (addon.children && addon.children.length > 0) {
                        addon.children.map(addonItem => {
                            if (addonItem.selected) {
                                let row = {
                                    product_name: addon.title + ': ' + addonItem.item,
                                    selling_price: addonItem.price,
                                    quantity: item.quantity,
                                    discount: 0,
                                    total: parseFloat(addonItem.price) * item.quantity,
                                }


                                addons.push(row);
                            }
                            return addonItem;
                        })
                    }
                    return addon;
                })
            }

            if (item.options && item.options.length > 0) {
                item.options.map(option => {
                    if (option.children && option.children.length > 0) {
                        option.children.map(optionItem => {
                            if (optionItem.selected) {
                                let row = {
                                    product_name: option.title + ': ' + optionItem.item,
                                    selling_price: optionItem.price,
                                    quantity: item.quantity,
                                    discount: 0,
                                    total: parseFloat(optionItem.price) * item.quantity,
                                }
                                options.push(row)
                            }
                            return optionItem;
                        })
                    }
                    return option;
                })
            }

            let obj = {
                product_name: item.name,
                selling_price: item.price,
                quantity: item.quantity,
                discount: item.discountRow,
                total: item.discountRow ? item.itemTotal - item.discountRow : item.itemTotal,
            };

            rows.push(obj);

            rows = [...rows, ...addons, ...options];
        });

        let summery = {
            subtotal: cartSubtotal,
            shipping: shippingCharges,
            discount: discount,
            total: cartSubtotal - shippingCharges - discount,
        }

        setSaleOrderItems(rows)
        setSaleOrder(summery)
        setShowSalePrintModal(true)
    }

    return (
        <>
            <Row>
                {/* Product Section */}
                <Col span={12}>
                    <Spin tip="syncing..." spinning={refreshSync} indicator={<LoadingOutlined style={{ fontSize: 24, }} spin />}>
                        <Products
                            products={products}
                            loading={loading}
                            search={handleSearch}
                            setProduct={setClickedProduct}
                            setAddonModal={setAddonModal}
                        >
                            {/* child */}
                            <Categories search={handleSearch} />
                        </Products>
                    </Spin>
                </Col>
                {/* Cart Section */}
                <Col span={12}>
                    <Cart
                        modal={openModal}
                        invoiceModal={setShowInvoiceModal}
                        discard={discardItems}
                        setKitchenModal={setKitchenModal}
                        kitchenCount={orders.length}
                        count={resumedData.length}
                        enableCartParking={posSetting.cart_parking}
                    >

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                            {/* Buttons */}
                            <Space>
                                <Button type="primary" danger ghost
                                    className="order-type-dinein"
                                    style={kitchenButtons === 0 ? { background: '#FF6260', color: '#fff' } : {}}
                                    onClick={() => { setDineInModal(true); setKitchenButtons(0) }}>
                                    Dine In
                                </Button>
                                <Button type="primary" ghost
                                    className="order-type-delivery"
                                    style={kitchenButtons === 1 ? { background: '#1890FF', color: '#fff' } : {}}
                                    onClick={() => { setDeliveryModal(true); setKitchenButtons(1) }}>
                                    Delivery
                                </Button>
                                <Button
                                    className="order-type-takeaway"
                                    style={kitchenButtons === 2 ? { background: '#003366', color: '#fff' } : { borderColor: '#003366', color: '#003366' }}
                                    onClick={() => { setTakeawayModal(true); setKitchenButtons(2) }}>
                                    Take Away
                                </Button>
                            </Space>

                            <Customers
                                customers={customers}
                                customerId={customerId}
                                setId={setCustomerId}
                                openModal={openFormModal}
                            />
                        </div>
                        {renderChildComponents()}
                    </Cart>
                </Col>
            </Row>

            {/* Modals */}
            <FormModal
                type={actionType}
                visible={showModal}
                toggle={setShowModal}
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}>
                {/* child */}
                <FormModalFields type={actionType} />
            </FormModal>

            <ResumedDiscardModal
                type={actionType}
                visible={showConfirmModal}
                toggle={setShowConfirmModal}
                discardCart={discardItems}>
                {/* child */}
                <ResumedDiscardFields type={actionType} resumed={resumed} resumedItems={resumedData} />
            </ResumedDiscardModal>

            <KitchenModal
                visible={kitchenModal}
                toggle={setKitchenModal}
                // handleComplete={handleComplete}
                orders={orders}
                products={products}
                resumed={resumed}
                customers={customers}
            />

            <EditCartItemModal
                visible={showEditItemModal}
                toggle={setShowEditItemModal}
                item={cartItem}
                checkbox={parentChecked}
                setCheckbox={setParentChecked}
                radio={childValue}
                setRadio={setChildValue}
                enableItemDiscount={posSetting.enable_item_discount}
            />

            <SellModal
                visible={showSellModal}
                toggle={setShowSellModal}
                customer={customer}
                customerId={customerId}
                shipping={parseInt(shippingCharges)}
                notes={notes}
                salePrintModal={setShowSalePrintModal}
                saleOrder={setSaleOrder}
                saleOrderItems={setSaleOrderItems}
                subtotal={cartSubtotal}
                formData={formData}
                setFormData={setFormData}
                setKitchenButtons={setKitchenButtons}
                waiters={staffs}
                tables={tables}
                setCustomerId={setCustomerId}
                branch_id={branch_id}
                orderId={orderId}
                setOrderId={setOrderId}
                cartSummaryTotal={cartSummaryTotal}
                discount={discount}
                setDiscount={setDiscount}

                setAmountDiscount={setItemsAmountDiscount}
                setPercentageDiscount={setItemsPercentageDiscount}
                setShippingCharges={setShippingCharges}
                setNotes={setNotes}
                setLoadScreen={setLoadScreen}
                store={store}
                db={db}
                srb={srb}
                takeaway={takeaway}

            // order={saleOrder}
            // orderItems={saleOrderItems}
            />

            <SalePrintModal
                visible={showSalePrintModal}
                toggle={setShowSalePrintModal}
                order={saleOrder}
                items={saleOrderItems}
                componentRef={componentRef}
                store={store}
                customer={customer}
                products={products}
                posPrintReceipt={posSetting.print_receipt}
                srbToggle={srb.active}
            />

            <PreviousInvoiceModal
                visible={showInvoiceModal}
                toggle={setShowInvoiceModal}
                printModal={setShowSalePrintModal}
                setOrder={setSaleOrder}
                setItems={setSaleOrderItems}
            />

            <DineInModal
                visible={dineInModal}
                toggle={setDineInModal}
                setFormData={setFormData}
                orderInfo={formData}
                buttonType={kitchenButtons}
                waiters={staffs}
                tables={tables}
            />

            <DeliveryModal
                visible={deliveryModal}
                toggle={setDeliveryModal}
                setFormData={setFormData}
                orderInfo={formData}
                buttonType={kitchenButtons}
            />

            <TakeawayModal
                visible={takeawayModal}
                toggle={setTakeawayModal}
                setFormData={setFormData}
                orderInfo={formData}
                buttonType={kitchenButtons}
                format={timeFormat}
                currentTime={currentTime}
            />

            <AddonModal
                visible={addonModal}
                toggle={setAddonModal}
                product={clickedProduct}
            />
        </>
    )

}

export default windowSize(SalesScreen) 