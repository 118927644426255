import React, { useState, useEffect } from 'react';
import { Table, Space, Tag, Tooltip, Form, Row, Col, notification, DatePicker, InputNumber, Modal, Input, Menu, Select, Button, Dropdown, Card, Typography, Radio } from 'antd';
import { useHistory } from 'react-router-dom';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { filterOrder, getOrders, updateStatus } from '../../data';
import windowSize from 'react-window-size';
import OrderStatus from '../../constant/orderStatus';
import { useForm } from 'antd/lib/form/Form';
import Search from 'antd/lib/transfer/search';
import { ALEF_CLIENT_ID, ALEF_CLIENT_SECRET, ALEF_URL, GOOGLE_MAPS_API_KEY } from '../../constants';
import { ajaxService, websocketService } from '../../services';

const { RangePicker } = DatePicker;
const { Option } = Select
const { Text } = Typography

function Orders({ windowWidth }) {

  const isMobile = windowWidth <= 768
  const history = useHistory();
  const form = useForm();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [code, setCode] = useState('');

  const [orderNumber, setOrderNumber] = useState(null)
  const [customer, setCustomer] = useState(null)
  const [customerName, setCustomerName] = useState(null)
  const [StatusOrder, setStatus] = useState(null)
  const [dateRange, setDateRang] = useState('')

  const [forceRefresh, setForceRefresh] = useState(true)
  const [type, setType] = useState(1)

  const [posOrders, setPosOrders] = useState([])
  const [ecommerceOrders, setEcommerceOrders] = useState([])
  const [orders, setOrders] = useState([])

  const [openRegisteration, setOpenRegisteration] = useState(false);
  const [skipIDs, setSkipIDs] = useState([]);
  const [orderID, setOrderID] = useState(0);
  const [driverID, setDriverID] = useState(0);

  const status = Object.values(OrderStatus)

  const columns = [
    {
      title: 'Order Number',
      dataIndex: 'order_number',
      key: 'order_number',

    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Customer',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <>
          <span>{record.name}</span><br />
          <span>{record.email}</span><br />
          <span>{record.phone}</span>
        </>
      )
    },
    {
      title: 'Shipping',
      dataIndex: 'shipping',
      key: 'shipping',
      ellipsis: {
        showTitle: false,
      },
      render: address => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: text => (
        <Tag color={'geekblue'}>
          {text ? text.toUpperCase() : ''}
        </Tag>
      ),
    },

    {
      title: 'Shipping Address',
      dataIndex: 'shipping_address',
      key: 'shipping_address',
    },

    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      // align: 'left',

      render: (text, record) => (
        <>

          <Space size={'middle'} direction={'vertical'}>
            <Space>
              {
                record.status != OrderStatus.COMPLETED &&
                <Dropdown overlay={
                  <Menu>
                    {status.slice(status.indexOf(record.status) + 1).map((status, key) => (
                      <Menu.Item onClick={() => changeStatus(record.id, status)} key={key}>
                        {status}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
                  placement="bottomCenter"
                >
                  <Button>Status</Button>
                </Dropdown>
              }

              <Button onClick={() => history.push({ pathname: "/orders/form/" + record.id })}>View</Button>
              {record.status == OrderStatus.COMPLETED && <Button type='primary' onClick={() => history.push({ pathname: "/orders/print/" + record.id })}>Print</Button>}
            </Space>
            {/* <Button onClick={() => requestAlef(record)} style={{ width: "100%" }}>Request Alef</Button> */}
          </Space>
        </>
      ),
    },
  ];

  const handleSearch = async () => {
    var formData = new FormData();

    if (orderNumber !== null) {
      formData.append('orderNumber', orderNumber);
    }
    if (StatusOrder !== null) {
      formData.append('StatusOrder', StatusOrder);
    }
    if (customer !== null) {
      formData.append('customer', customer);
    }
    if (dateRange !== '') {
      formData.append('dateRange', dateRange);
    }
    if (customerName !== null) {
      formData.append('customerName', customerName);
    }
    const response = await filterOrder(formData);

    if (response) {
      setPosOrders(response.filter(i => i.platform == "POS"));
      setEcommerceOrders(response.filter(i => i.platform != "POS"));
    } else {
      notification.open({ message: 'No Order Found...', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
    }

  }
  const clear = () => {
    setOrderNumber(null)
    setCustomer(null)
    setStatus(null)
    setDateRang('')
    setCustomerName(null)
  }

  const changeStatus = async (id, status) => {
    let data = {
      id: id,
      status,
    }
    const update_status = await updateStatus(id, data);
    if (update_status) {
      setForceRefresh(true)
      notification.open({ message: 'Order status has been updated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
    } else {
      notification.open({ message: 'Unable to update order status', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })

    }
  }

  const initialize = async () => {
    const data = await getOrders(forceRefresh);
    if (data) {
      setOrders(data.filter(i => type === 1 ? i.platform == "POS" : i.platform != "POS"))
      setPosOrders(data.filter(i => i.platform == "POS"));
      setEcommerceOrders(data.filter(i => i.platform != "POS"));

      setForceRefresh(false)
    }
  }

  useEffect(() => {
    setSkipIDs([...skipIDs, driverID])
  }, [skipIDs.length])

  useEffect(() => {
    setSkipIDs([])
  }, [orderID])

  useEffect(() => {
    if (forceRefresh) {
      initialize()
      setForceRefresh(false)
      clear()
    }
  }, [forceRefresh])

  const renderFilter = (
    <Form layout={'vertical'}>
      <Row gutter={16} >
        <Col span={isMobile ? 24 : 6} >
          <Form.Item label="Order Number" >
            <Select
              onChange={(v) => setOrderNumber(v)}
              // value={orderNumber === '' ? 'Select Order' : orderNumber}
              value={orderNumber}
              placeholder="Select Order"
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {orders.map(order => {
                return <Option value={order.order_number} key={"order-" + order.id} >{order.order_number}</Option>
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={isMobile ? 24 : 6}>
          <Form.Item label="Order Status">
            <Select
              allowClear
              placeholder="Select Order Status"
              value={StatusOrder}
              onChange={(v) => setStatus(v)}
            >
              {status.map(status => {
                return <Option value={status} key={"status-" + status} >{status}</Option>
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={isMobile ? 24 : 6} >
          <Form.Item label="Customer" >
            <Select
              showSearch
              allowClear
              value={customer}
              placeholder="Select Customer"
              onChange={(v) => setCustomer(v)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {orders.map(customer => {
                return <Option value={customer.name} key={"customer-" + customer.id} >{customer.name}</Option>
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={isMobile ? 24 : 6}>
          <Form.Item label="Date Range" >
            <RangePicker style={{ width: '100%' }} value={dateRange} onChange={(e) => setDateRang(e)} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={isMobile ? 24 : 6}>
          <Form.Item label="Customer Filter" >
            <Input value={customerName}
              placeholder="Customer Name"
              onChange={(e) => setCustomerName(e.target.value)} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={isMobile ? '' : <Text>&nbsp;</Text>}>
            <Space>
              <Button onClick={handleSearch}>Search</Button>
              <Button onClick={clear}>Clear</Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )

  const renderOrders = () => {
    return (
      <>
        <Radio.Group optionType="button" buttonStyle="solid" defaultValue={type} onChange={(e) => { setType(e.target.value); setForceRefresh(true) }}>
          <Radio value={2}>Ecommerce</Radio>
          <Radio value={1}>POS</Radio>
        </Radio.Group>

        <br /><br />

        <Row>
          <Col span={24}>
            <Table
              dataSource={type === 1 ? posOrders : ecommerceOrders}
              columns={columns}
              rowKey="id"
            />
          </Col>
        </Row>
      </>
    )
  }

  const renderFilterMobile = () => {
    // return (
    //   <>
    //     <Form>
    //       <Row gutter={16} >
    //         <Col span={24} >
    //           <Form.Item label="Order Number" >
    //             <Select
    //               onChange={(v) => setOrderNumber(v)}
    //               value={orderNumber === '' ? 'Select Order' : orderNumber}
    //               showSearch
    //               allowClear
    //               filterOption={(input, option) =>
    //                 option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //               }
    //             >
    //               {dataSource.map(order => {
    //                 return <Option value={order.order_number} key={"order-" + order.id} >{order.order_number}</Option>
    //               })}
    //             </Select>
    //           </Form.Item>
    //         </Col>
    //         <Col span={24}>
    //           <Form.Item label="Order Status">
    //             <Select
    //               allowClear
    //               // placeholder="Select Order Status"
    //               value={StatusOrder === '' ? 'Select Order Status' : StatusOrder}
    //               onChange={(v) => setStatus(v)}
    //             >
    //               {orderStatus.map(status => {
    //                 return <Option value={status} key={"status-" + status} >{status}</Option>
    //               })}
    //             </Select>
    //           </Form.Item>
    //         </Col>
    //         <Col span={24} >
    //           <Form.Item label="Customer" >
    //             <Select
    //               showSearch
    //               allowClear
    //               value={customer === '' ? 'Select Customer' : customer}
    //               onChange={(v) => setCustomer(v)}
    //               filterOption={(input, option) =>
    //                 option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //               }
    //             >
    //               {dataSource.map(customer => {
    //                 return <Option value={customer.name} key={"customer-" + customer.id} >{customer.name}</Option>
    //               })}
    //             </Select>
    //           </Form.Item>
    //         </Col>
    //         <Col span={24}>
    //           <Form.Item label="Date Range" >
    //             <RangePicker style={{ width: '100%' }} value={dateRange} onChange={(e) => setDateRang(e)} />
    //           </Form.Item>
    //         </Col>
    //         <Col span={24}>
    //           <Form.Item label="Customer Filter" >
    //             <Input value={customerName === '' ? 'Customer Name' : customerName}
    //               onChange={(e) => setCustomerName(e.target.value)} />
    //           </Form.Item>
    //         </Col>
    //         <Col span={24}>
    //           <Form.Item>
    //             <Space>
    //               <Button type='primary' onClick={handleSearch}>Search</Button>
    //               <Button onClick={clear}>Clear</Button>
    //             </Space>
    //           </Form.Item>
    //         </Col>
    //       </Row>
    //     </Form>
    //   </>
    // )

    return "Workiing............"
  }

  const renderOrdersMobile = () => {
    // return (
    //   <>
    //     {renderFilterMobile()}
    //     {
    //       dataSource.map(order => {
    //         return (
    //           <>
    //             < Card style={{ width: '100%' }}
    //               actions={[
    //                 <Space >
    //                   <Dropdown overlay={
    //                     <Menu >
    //                       {orderStatus.map((status, key) => (
    //                         <Menu.Item onClick={() => changeStatus(order.id, status)} key={key}> {status} </Menu.Item>
    //                       ))}
    //                     </Menu>}
    //                     placement="bottom">
    //                     <Button>Status</Button>
    //                   </Dropdown>
    //                   <Button type='primary' onClick={() => history.push({ pathname: "/orders/form/" + order.id })}>View</Button>
    //                 </Space>
    //               ]}>
    //               <Text style={{ fontWeight: 'bolder' }}>Order Number : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.order_number}</span></Text>
    //               <br />
    //               <Text style={{ fontWeight: 'bolder' }}>Date : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.date}</span></Text>
    //               <br />
    //               <Text style={{ fontWeight: 'bolder' }}>Customer :
    //                 <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.name}</span><br />
    //                 <span style={{ fontWeight: 'lighter', marginLeft: 78 }}>{order.email}</span><br />
    //                 <span style={{ fontWeight: 'lighter', marginLeft: 78 }}>{order.phone}</span>
    //               </Text>
    //               <br />
    //               <Text style={{ fontWeight: 'bolder' }}>Shipping : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.shipping}</span></Text>
    //               <br />
    //               <Text style={{ fontWeight: 'bolder' }}>Status :
    //                 <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
    //                   <Tag color='geekblue'>
    //                     {order.status}
    //                   </Tag>
    //                 </span>
    //               </Text>
    //               <br />
    //               <Text style={{ fontWeight: 'bolder' }}>Shipping Address : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.shipping_address}</span></Text>
    //               <br />
    //               <Text style={{ fontWeight: 'bolder' }}>Total : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.total}</span></Text>
    //               <br />
    //             </Card>
    //             <br />
    //           </>
    //         )
    //       })
    //     }
    //   </>
    // )

    return "Workiing............"
  }

  const renderContent = () => {
    if (isMobile) {
      return renderOrdersMobile()
    }
    else {
      return renderOrders()
    }
  }

  const requestAlef = async (order) => {

    localStorage.setItem('orderID', order.id)
    setOrderID(order.id)

    const token = await localStorage.getItem('alefToken');

    if (token == null) {
      setOpenRegisteration(true)
    } else {
      searchDeliveryPerson()
    }

  }

  const register = async () => {

    let data = {
      client_id: ALEF_CLIENT_ID,
      client_secret: ALEF_CLIENT_SECRET,
      is_captain: false,
      username: `${phoneNumber}`,
      password: `${phoneNumber}`,
      grant_type: 'password',
      hash: '',
      device_token: 'web',
      locale: 'so'
    };

    const rawResponse = await fetch(ALEF_URL + '/api/oauth/token', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const response = await rawResponse.json();

    localStorage.setItem('tempToken', response.accessToken);
    localStorage.setItem('alefVerification', response.verificationCode);

    notification.open({
      message: 'OTP Sent',
      description: 'OTP has been sent to phone number.',
    });
  }

  const searchDeliveryPerson = async () => {

    const store_id = localStorage.getItem('store_id');
    const branch_id = localStorage.getItem('posBranchId');

    const order = orders.find(o => o.id == localStorage.getItem('orderID'))

    ajaxService.removeDependency()

    const [store, branch] = await Promise.all([
      ajaxService.get('stores/' + store_id),
      ajaxService.get(`stores/${store_id}/branches/${branch_id}`)
    ])

    const { latitude, longitude } = branch.data.payload;

    const pickupLatitude = parseFloat(latitude);
    const pickupLongitude = parseFloat(longitude);
    const dropoffLatitude = parseFloat(order.latitude);
    const dropoffLongitude = parseFloat(order.longitude);

    let params = JSON.stringify({
      latitude: pickupLatitude,
      longitude: pickupLongitude,
      type: order.shipping_method || 'Walking',
      shipping: order.shipping,
      locale: 'so',
      skip: []
    });

    const rawResponse = await fetch(ALEF_URL + '/api/booking/delivery', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('alefToken')}`
      },
      body: params
    });
    const response = await rawResponse.json();

    let data = {
      pickup: { location: branch.address, latitude: pickupLatitude, longitude: pickupLongitude, distance: 0, duration: 0, nearby: '' },
      dropoff: { location: order.shipping_address, latitude: dropoffLatitude, longitude: dropoffLongitude, distance: 0, duration: 0 },
      customer: { name: store.name, photo: store.logo, rate: 0, id: store.id },
      trip: { fare: response.fare, currency: branch.currency },
    }

    initWebSocket(response.user_id, data);

  }

  const initWebSocket = async (user_id, data) => {

    if (user_id > 0) {
      const echo = await websocketService();
      let channel = echo.private(`driver.${user_id}`);
      setDriverID(user_id);

      console.log(channel)

      channel.listenForWhisper('response', ({ status, driverID }) => {
        if (status == 'rejected') {
          if (driverID > 0) {
            let ids = [...(skipIDs || [])]
            ids.push(driverID);
            setSkipIDs(ids);
          }
        } else if (status == 'accepted') {
          // activeTrip();// refirection not required
          // update order with ajax in alef eat
          //notification
        }
      });

      channel.whisper('delivery-ride', data);
    }
  }

  const verify = async () => {
    let savedCode = await localStorage.getItem('alefVerification');

    if (savedCode == code) {
      const alefToken = await localStorage.getItem('tempToken')
      localStorage.setItem('alefToken', alefToken);
      searchDeliveryPerson()
      setOpenRegisteration(false)
    } else {
      notification.open({
        message: 'Validation Failed',
        description: 'OTP validation has been failed.',
      });
    }
  }

  return (
    <>
      {renderFilter}
      {renderContent()}
      <Modal title="Alef Express Validation"
        open={openRegisteration}
        onOk={form.submit}
        onCancel={() => setOpenRegisteration(false)}
        centered={true}
        footer={null}
        width={350}
        style={{ width: '100%' }}
      >
        <Space size={'middle'} direction={'vertical'}>

          <Space size={'small'}>
            <Input.Group >
              <InputNumber
                style={{
                  width: 'calc(100% - 80px)',
                }}
                placeholder='Phone Number'
                defaultValue=""
                onChange={(t) => setPhoneNumber(t)}
              />
              <Button type="primary" onClick={register}>Submit</Button>
            </Input.Group>
          </Space>
          <Space size={'small'}>
            <Input.Group >
              <InputNumber
                style={{
                  width: 'calc(100% - 80px)',
                }}
                placeholder='OTP'
                defaultValue=""
                onChange={(t) => setCode(t)}
              />
              <Button type="primary" onClick={verify}>Verify</Button>
            </Input.Group>
          </Space>

        </Space>
      </Modal>
    </>
  )

}

export default windowSize(Orders)