import { Button, Select } from "antd"
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select
const Customers = ({ customers, customerId, setId, openModal }) => (
    <div style={{ display: 'flex', textTransform: 'capitalize' }}>
        <Select
            allowClear
            showSearch
            onChange={(e) => setId(e)}
            placeholder="Select Customer"
            style={{ width: '100%' }}
            optionFilterProp="children"
            defaultValue={customerId ? customerId : null}
            key={customerId}
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {customers.map(customer => (
                <Option value={customer.id} key={customer.id} style={{ textTransform: "capitalize" }}>
                    {customer.name + ' : ' + customer.phone}
                </Option>
            ))}
        </Select>
        <Button
            icon={<PlusOutlined />}
            style={{ borderRadius: '0px 2px 2px 0px' }}
            onClick={() => openModal('addCustomer')}
        />
    </div >
)

export default Customers