import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Col, Collapse, Row, Typography } from "antd";
import windowSize from "react-window-size";
import { getCashRegisterEntries, getCashRegisters } from "../../data";

const { Panel } = Collapse;
const { Title, Text } = Typography

function CashReportDetails() {

    const [dataSource, setDataSource] = useState([])
    const [forceRefresh, setForceRefresh] = useState(true)

    const [opening, setOpening] = useState('')
    const [closing, setClosing] = useState('')
    const [incomeTotals, setIncomeTotals] = useState('')
    const [expenseTotals, setExpenseTotals] = useState('')
    const [incomeRows, setIncomeRows] = useState('')
    const [expenseRows, setExpenseRows] = useState('')
    const [cashFlow, setCashFlow] = useState('')
    const [difference, setDifference] = useState('')

    let { id } = useParams()

    const initialize = async () => {
        const data = await getCashRegisterEntries(id)

        if (data != null) {
            setDataSource(data)

            let incomeAmount = 0
            let expenseAmount = 0

            let incomeRow = 0
            let expenseRow = 0
            data.map(i => {
                if (i.type != "Opening" && i.type != "Closing" && i.addition == 1) {
                    incomeRow += 1
                    incomeAmount += parseInt(i.amount)
                } else if (i.type != "Opening" && i.type != "Closing" && i.addition == 0) {
                    expenseRow += 1
                    expenseAmount += parseInt(i.amount)
                }
            })

            setIncomeTotals(incomeAmount.toFixed(2))
            setIncomeRows(incomeRow)
            setExpenseTotals(expenseAmount.toFixed(2))
            setExpenseRows(expenseRow)

            let opening = data.find(i => i.type == "Opening");
            let closing = data.find(i => i.type == "Closing");

            setCashFlow(((parseInt(opening.amount) + parseInt(incomeAmount)) - expenseAmount).toFixed(2))

            setOpening(opening)
            setClosing(closing)
        }
    }

    const loadCashRegister = async () => {
        let response = await getCashRegisters(forceRefresh)
        if (response) {
            let cashRegister = response.find(i => i.id == id);
            setDifference(cashRegister.difference);
            setForceRefresh(false)
        }
    }

    useEffect(() => {
        initialize()
        loadCashRegister()
    }, [])

    const renderHeading = () => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title level={3}>Cash Report Details</Title>
        </div>
    )


    const renderEntriesTable = () => (
        <Row>
            <Col span={24}>

                <Row style={{ backgroundColor: '#fafafa', padding: '15px 20px' }}>
                    <Col span={12}><b>Particulars</b></Col>
                    <Col span={3} style={{ textAlign: 'center' }}><b>Amount</b></Col>
                </Row>
                <Row style={{ backgroundColor: '#fff', padding: '15px 17px' }}>
                    <Col span={12}>Oppening Float</Col>
                    <Col span={3} style={{ textAlign: 'center' }}>{opening.amount}</Col>
                </Row>

                <Collapse>
                    {/* Add Sales */}
                    <Panel
                        key="1"
                        header={
                            <Row>
                                <Col span={12}>Total Inflow <i style={{ opacity: 0.8 }}>( {incomeRows + " records are there "})</i></Col>
                                <Col span={3}><Text style={{ marginLeft: 37 }}>{incomeTotals}</Text></Col>
                            </Row>
                        }>
                        {dataSource.map((cashRegisterEntry) => {
                            if (cashRegisterEntry.type != "Opening" && cashRegisterEntry.type != "Closing" && cashRegisterEntry.addition == 1) {
                                return (
                                    <>
                                        <Row style={{ backgroundColor: '#fff', padding: '15px 20px' }}>
                                            <Col span={12}>{cashRegisterEntry.description}</Col>
                                            <Col span={3} style={{ textAlign: 'center' }}>{cashRegisterEntry.amount}</Col>
                                        </Row>
                                    </>
                                )
                            }
                        })}
                    </Panel>
                    {/* Less Sales */}
                    <Panel
                        key="2"
                        header={
                            <Row>
                                <Col span={12}>Total Outflow <i style={{ opacity: 0.8 }}>( {expenseRows + " records are there "})</i></Col>
                                <Col span={3}><Text style={{ marginLeft: 37 }}>{expenseTotals}</Text></Col>
                            </Row>
                        }>
                        {dataSource.map((cashRegisterEntry) => {
                            if (cashRegisterEntry.type != "Opening" && cashRegisterEntry.type != "Closing" && cashRegisterEntry.addition == 0) {
                                return (
                                    <Row style={{ backgroundColor: '#fff', padding: '15px 20px' }}>
                                        <Col span={12}>{cashRegisterEntry.description}</Col>
                                        <Col span={3} style={{ textAlign: 'center' }}>{cashRegisterEntry.amount}</Col>
                                    </Row>
                                )
                            }
                        })}
                    </Panel>
                </Collapse>

                <Row style={{ backgroundColor: '#fff', padding: '15px 17px' }}>
                    <Col span={12}>Expected Cash Flow</Col>
                    <Col span={3} style={{ textAlign: 'center' }}>{cashFlow}</Col>
                </Row>
                <Row style={{ backgroundColor: '#fff', padding: '15px 17px' }}>
                    <Col span={12}>Closing Float</Col>
                    <Col span={3} style={{ textAlign: 'center' }}>{closing.amount}</Col>
                </Row>
                <Row style={{ backgroundColor: '#fff', padding: '15px 17px' }}>
                    <Col span={12}>Difference</Col>
                    <Col span={3} style={{ textAlign: 'center' }}>{difference}</Col>
                </Row>
            </Col>
            <Col span={2}></Col>
        </Row >
    )

    return (
        <Card>
            {renderHeading()}
            <br />
            {renderEntriesTable()}
        </Card>
    )

}

export default windowSize(CashReportDetails)